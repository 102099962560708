import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { API_BASE_URL } from "../app.config";
import { EventDto, EventInterface } from "../dto/event-dto";

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public events$: Subject<EventDto> = new Subject();
  private subjects: {
    [key: string]: Subject<EventDto>;
  } = {};

  constructor() {
    this.events$.subscribe((event: EventDto) => {
      if (!this.subjects[event.type]) return;
      this.subjects[event.type].next(event);
    });
  }

  getEvents(type: string): Subject<EventDto> {
    if (!Object.keys(this.subjects).find((key: string) => key === type)) {
      this.subjects[type] = new Subject();
    }

    return this.subjects[type];
  }

  connectToSSE(): void {
    const source: EventSource = new EventSource(API_BASE_URL + '/events-stream');
    source.addEventListener('message', (e: {data: string}) => {
      try {
        const data: EventInterface = JSON.parse(e.data);
        console.log(new EventDto(data));
        this.events$.next(new EventDto(data));
      } catch (error) {
        console.log(e.data);
      }
    }, false);

    source.addEventListener('open', function(e) {
      console.log("Соединение с SSE установлено!");
    }, false);

    source.addEventListener('error', function(e) {
      if (e.eventPhase == EventSource.CLOSED) {
        console.warn("Соединение с SSE разорвано!");
      }
    }, false);
  }
}
