import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './pages/auth/auth.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { UsersComponent } from './pages/users/users.component';
import { ManagerCalendarComponent } from './pages/manager-calendar/manager-calendar.component';
import { UserDeactivatedComponent } from './pages/user-deactivated/user-deactivated.component';
import { TaskComponent } from './pages/tasks/task/task.component';
import { UserComponent } from './pages/users/user/user.component';
import { HelpComponent } from './pages/help/help.component';

const routes: Routes = [
  { path: 'loading'},
  { path: 'deactivated', component: UserDeactivatedComponent},
  { path: 'tasks', component: TasksComponent},
  { path: 'tasks/:id', component: TaskComponent },
  { path: 'calendar', component: ManagerCalendarComponent},
  { 
    path: 'auth', 
    component: AuthComponent,
    children: [{
      path: "logout",
      pathMatch: "full"
    }]
  },
  { path: 'users', component: UsersComponent },
  { path: 'users/:id', component: UserComponent },
  { path: 'help', component: HelpComponent },
  { path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
