export interface EventInterface {
  id: number;
  type: string;
  content?: string;
  createdAt: number;
  userId?: number;
}

export class EventDto {
  id: number;
  type: string;
  content?: any;
  createdAt: number;
  userId?: number;

  constructor(options: EventInterface) {
    this.id = options.id;
    this.type = options.type;
    this.content = JSON.parse(options.content);
    this.createdAt = options.createdAt;
    this.userId = options.userId;
  }
}
