import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { AttachmentDto } from 'src/app/dto/attachment-dto';
import { ChatMemberDto } from 'src/app/dto/chat-member-dto';
import { ChatMessageDto } from 'src/app/dto/chat-message-dto';
import { EventDto } from 'src/app/dto/event-dto';
import { UserDto } from 'src/app/dto/user-dto';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { EventsService } from 'src/app/services/events.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PositionsService } from 'src/app/services/positions.service';
import { ChatService } from './service/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.styl'],
  providers: [ChatService]
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  members: ChatMemberDto[] = [];
  currentMember: ChatMemberDto;
  messages: ChatMessageDto[] = [];
  selectedMessages: ChatMessageDto[] = [];
  currentMessage: ChatMessageDto = null;

  @Input() mainMember?: UserDto;
  @Input() guestMember?: UserDto;

  @Input("id") chatId: number;
  @Input() disabled: string = null;

  form: FormGroup;

  @ViewChild("chatBody") dialogRef: ElementRef<HTMLDivElement>;
  @ViewChild("input") inputRef: ElementRef<HTMLTextAreaElement>;
  @ViewChild('newMessageSong') newMessageSongRef: ElementRef<HTMLAudioElement>;
  
  files: File[] = [];
  currentAttachments: AttachmentDto[] = [];

  isMessageSending: boolean = false;
  isMessageEditing: boolean = false;
  filesUploaded$: Subject<void> = new Subject();

  @ViewChild("fileInput") fileInputRef: ElementRef<HTMLInputElement>;

  private alive: boolean = false;

  constructor(
    private svc$: ChatService, 
    private app: AppComponent, 
    private positionsSvc$: PositionsService, 
    private cdr: ChangeDetectorRef,
    private eventsSvc$: EventsService,
    private attachmentsSvc$: AttachmentsService
  ) { }

  addMessage(message: ChatMessageDto): void {
    if (this.getMsgIndex(message.id) > -1) return;

    this.messages.push(message);

    if (!message.isRead && message.memberId !== this.currentMember.id) {
      this.svc$.updateMessage(new ChatMessageDto({
        ...message,
        isRead: true
      })).subscribe();
    }
  }

  isDisabled(): boolean {
    return this.disabled !== null;
  }

  copyMessageText(message: ChatMessageDto): void {
    const area = document.createElement('textarea');
    area.style.opacity = '0';
    area.style.width = '0px';
    area.style.height = '0px';
    area.value = message.text;
    document.body.appendChild(area);

    area.select();
    document.execCommand("copy");
    document.body.removeChild(area);

    this.app.showToast("Буфер обмена", "Текст успешно скопирован!");
  }

  isEditableMessage(message: ChatMessageDto): boolean {
    return this.currentMember && message.memberId === this.currentMember.id;
  }

  deleteSelectedMessages(): void {
    this.selectedMessages.forEach((message: ChatMessageDto) => {
      message.setIsSelected(false);
    });

    this.selectedMessages = [];
  }

  selectMessage(message: ChatMessageDto): void {
    if (!this.isEditableMessage(message)) return;

    const index: number = this.selectedMessages.findIndex(m => m.id === message.id);
    
    if (index > -1) {
      this.selectedMessages.splice(index, 1);
      message.setIsSelected(false);
      return;
    }

    message.setIsSelected(true);
    this.selectedMessages.push(message);
  }

  addMember(): void {
    if (this.currentMember) return; 

    const member: ChatMemberDto = new ChatMemberDto({
      chatId: this.chatId,
      userId: this.app.user.id
    });

    this.svc$.addMember(member).subscribe(memberId => {
      this.getMembers();
    });
  }

  canSendMessage(): boolean {
    if (this.isMessageSending || !this.form.controls['text'].value && this.files.length === 0 || !this.currentMember) return false;
    return true;
  }

  getMessageInputPlaceholder(): string {
    return document.body.clientWidth > 991 ? 'Напишите сообщение...' : 'Сообщение';
  }

  addFiles(): void {
    const fileInput$: HTMLInputElement = this.fileInputRef.nativeElement;
    this.files = Array.from<File>(fileInput$.files);
    fileInput$.value = "";
  }

  getGuestMemberPosition(): string {
    return this.positionsSvc$.getPosition(this.guestMember.positionId)?.name.toLowerCase();
  }

  getUserName(memberId: number): string {
    const member: ChatMemberDto = this.members.find((member: ChatMemberDto) => member.id === memberId);
    if (!member) return "No name";
    return member.user.getFullName();
  }

  getUserPosition(memberId: number): string {
    const member: ChatMemberDto = this.members.find((member: ChatMemberDto) => member.id === memberId);
    if (!member) return "No position";
    const position: string = this.positionsSvc$.getPosition(member.user.positionId).name;
    return '(' + position.slice(0, 1).toLowerCase() + ')';
    // return '(' + position.toLowerCase() + ')';
  }

  isFullMessage(index: number): boolean {
    if (index === 0) return true;

    const message: ChatMessageDto = this.messages[index];
    const prevMessage: ChatMessageDto = this.messages[index - 1];

    return (message.getDate().getTime() - prevMessage.getDate().getTime()) > 60 * 60 * 1000 || prevMessage.memberId !== message.memberId;
  }

  isNewDayMessage(message: ChatMessageDto): boolean {
    const index: number = this.messages.findIndex((m) => m.id === message.id);
    if (index === 0) return true;
    const prevMessage: ChatMessageDto = this.messages[index - 1];
    return prevMessage.getDate().getDate() !== message.getDate().getDate();
  }

  dialogScrollToBottom(): void {
    const dialog$: HTMLDivElement = this.dialogRef.nativeElement;

    setTimeout(() => {
      dialog$.scrollTo({
        left: 0,
        top: dialog$.scrollHeight
      });
    }, 1000);
  }

  updateTextareaHeight(): void {
    const $textarea: HTMLTextAreaElement = this.inputRef?.nativeElement;
    $textarea.rows = Math.floor(($textarea.scrollHeight - 12) / 24);

    setTimeout(() => {
      if ($textarea.value === "") {
        $textarea.rows = 1;
      } 
    });
  }

  onPressEnter(event: Event): void {
    event.preventDefault();

    if (this.isMessageEditing) {
      this.updateMessage();
    } else {
      this.sendMessage();
    }
  }

  deleteMessages(): void {
    this.selectedMessages.forEach((message: ChatMessageDto) => {
      this.deleteMessage(message);
    });
  }

  deleteMessage(message: ChatMessageDto): void {
    this.svc$.deleteMessage(message).subscribe(() => {
      const index: number = this.getMsgIndex(message.id);

      if (index === -1) return;

      this.messages.splice(index, 1);
    });
  }

  editMessage(message: ChatMessageDto): void {
    this.isMessageEditing = true;
    this.currentMessage = message;
    this.form.controls['text'].setValue(message.text);
    this.updateTextareaHeight();
    this.focusInput();
  }

  updateMessage(): void {
    if (!this.isMessageEditing) return;

    this.isMessageSending = true;

    const message: ChatMessageDto = new ChatMessageDto({
      ...this.currentMessage,
      text: this.form.value.text
    });

    this.svc$.updateMessage(message).subscribe(() => {
      this.currentMessage = null;
      this.isMessageSending = false;
      this.isMessageEditing = false;
      this.initForm();
      this.updateTextareaHeight();
    });
  }

  sendMessage(skipExceptions: boolean = false): void {
    if (!this.canSendMessage() && !skipExceptions) return;

    this.isMessageSending = true;

    if (this.files.length > 0 && !this.isFilesUploaded()) {
      this.filesUploaded$.pipe(take(1)).subscribe(() => {
        this.sendMessage(true);
      });
      return;
    }

    const message: ChatMessageDto = new ChatMessageDto({
      text: this.form.value.text,
      memberId: this.currentMember.id
    });

    if (this.currentAttachments.length > 0) {
      message.attachments = this.currentAttachments;
    }

    this.svc$.sendMessage(message).subscribe((messageId: number) => {
      message.id = messageId;
      this.setMessageIdToAttachments(messageId);
      this.addMessage(message);
      this.dialogScrollToBottom();
      this.initForm();
      this.isMessageSending = false;
      this.focusInput();
    });
  }

  getMessages(): void {
    this.svc$.getMessages(this.chatId).subscribe((messages: ChatMessageDto[]) => {
      messages.forEach((message: ChatMessageDto) => this.addMessage(message));
      this.dialogScrollToBottom();
    });
  }

  isFilesUploaded(): boolean {
    return (
      this.files.length === this.currentAttachments.length && 
      this.currentAttachments.findIndex(attachment => !(attachment instanceof AttachmentDto)) === -1
    );
  }

  setMessageIdToAttachments(messageId: number): void {
    if (this.currentAttachments.length === 0) return;

    this.currentAttachments.forEach(attachment => {
      attachment.messageId = messageId;
    });

    this.attachmentsSvc$.updateAttachments(this.currentAttachments).subscribe(() => {
      this.files = [];
      this.currentAttachments = [];
    });
  }

  onFileUploaded(attachment: AttachmentDto, file: File): void {
    const index: number = this.files.findIndex(f => f.name === file.name && f.size === file.size && f.lastModified === file.lastModified);
    this.currentAttachments[index] = attachment;

    if (this.isFilesUploaded()) {
      this.filesUploaded$.next();
    }
  }
  
  onFileRemoved(file: File): void {
    const index: number = this.files.findIndex(f => f.name === file.name && f.size === file.size && f.lastModified === file.lastModified);
    this.files.splice(index, 1);
    this.currentAttachments.splice(index, 1);

    if (this.isFilesUploaded()) {
      this.filesUploaded$.next();
    }
  }

  initForm(): void {
    this.form = new FormGroup({
      text: new FormControl('')
    });
  }

  focusInput(): void {
    this.cdr.detectChanges();
    
    setTimeout(() => {
      this.inputRef?.nativeElement?.focus();
    });
  }

  playNewMessageSong(): void {
    this.newMessageSongRef.nativeElement.play();
  }

  getCurrentMember(): void {
    this.currentMember = this.members.find((member: ChatMemberDto) => this.app.user.id === member.userId);
  }

  getMembers(): void {
    this.svc$.getMembers(this.chatId).subscribe(members => {
      this.members = members;
      this.getCurrentMember();
      this.getMessages();
    });
  }

  initMessageEventsHandler(): void {
    this.eventsSvc$.getEvents('chat_messages_add')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        if (event.content.chatId !== this.chatId) return;

        const index: number = this.getMsgIndex(event.content.modelId);

        this.svc$.getMessage(event.content.modelId).subscribe((message: ChatMessageDto) => {
          if (index > -1) {
            this.messages[index] = message;
          } else {
            this.addMessage(message);
            this.dialogScrollToBottom();
          }
        });
      });

    this.eventsSvc$.getEvents('chat_messages_update')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        if (event.content.chatId !== this.chatId) return;

        const messageId: number = event.content.modelId;

        this.svc$.getMessage(messageId).subscribe((message: ChatMessageDto) => {
          this.messages[this.getMsgIndex(messageId)] = message;
        });
      });

    this.eventsSvc$.getEvents('chat_messages_delete')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        if (event.content.chatId !== this.chatId) return;
        const index: number = this.getMsgIndex(event.content.modelId); 
        if (index === -1) return;
        this.messages.splice(index, 1);
      });

    this.eventsSvc$.getEvents('chat_message_attachments_update')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        const attachmentId: number = event.content.modelId;

        this.attachmentsSvc$.getAttachment(attachmentId).subscribe((attachment: AttachmentDto) => {
          const index: number = this.getMsgIndex(attachment.messageId); 
          if (index === -1) return;
          
          this.svc$.getMessage(attachment.messageId).subscribe((message: ChatMessageDto) => {
            if (ChatMessageDto.equals(this.messages[index], message)) return;
            this.messages[index] = message;
          });
        });
      });
  }

  getMsgIndex(messageId: number): number {
    return this.messages.findIndex((m: ChatMessageDto) => m.id === messageId);
  }

  ngOnInit(): void {
    this.alive = true;
    this.initForm();
    this.getMembers();
    this.initMessageEventsHandler();
  }

  ngAfterViewInit(): void {
    this.focusInput();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
