<div class="serachForm">
  <input 
    #searchInput
    class="form-control form-control-sm" 
    type="text" 
    placeholder="Поиск..."
    (input)="searchValueChange()"
    (click)="searchValueChange()">
  
  <div class="searchForm__close" title="Закрыть поиск" (click)="close()">
    <i class="fas fa-times"></i>
  </div>

  <div class="collapse searchForm__collapse" id="searchFormResult">
    <div class="card card-body">
      <div *ngFor="let task of filteredTasks" class="task" (click)="taskClick(task)">
        <p class="task__title">{{task.client?.address ? task.client?.address : 'Без адреса'}}</p>
        <p class="task__date">{{task.date | date:'d MMM'}}</p>
        <div class="task__users">
          <p *ngIf="task.manager" class="task__user"><i class="fas fa-headphones-alt"></i>&nbsp; {{task.manager?.getFullName()}}</p>
          <p *ngIf="task.responsibleUser" class="task__user"><i class="far fa-handshake"></i>&nbsp; {{task.responsibleUser?.getFullName()}}</p>
        </div>
      </div>

      <div *ngIf="filteredTasks.length === 0" class="info">
        По вашему запросу ничего не найдено.
      </div>
    </div>
  </div>
</div>
