import { PositionRoles } from "../helpers/position-roles.enum";

export class PositionDto {
  id: number;
  role: PositionRoles;
  name: string;

  constructor(options: {
    id: number,
    role: PositionRoles,
    name: string
  }) {
    this.id = options.id;
    this.role = options.role;
    this.name = options.name;
  }
}