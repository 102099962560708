export interface ClientInterface {
  id: number;
  name: string;
  surname: string;
  phone: string;
  address: string;
  amoId: number;
}

export class ClientDto {
  id: number;
  name: string;
  surname: string;
  phone: string;
  address: string;
  amoId: number;

  constructor(options: {
    id: number,
    name: string,
    surname: string,
    phone: string,
    address: string,
    amoId: number
  } = {
    id: 0,
    name: "No name",
    surname: "No surname",
    phone: "No phone",
    address: "No address",
    amoId: 0
  }) {
    this.id = options.id;
    this.name = options.name;
    this.surname = options.surname;
    this.phone = options.phone;
    this.address = options.address;
    this.amoId = options.amoId;
  }
}