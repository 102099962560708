<section class="calendar">
  <div class="calendar__grid">
    <div *ngFor="let day of days" class="calendar__item day">
      <div class="day__header">
        <p class="day__date">{{day.customDate.date | date:'EEEE, d MMMM' | titlecase}}</p>
      </div>
      <div class="day__body">
        <div 
          *ngFor="let interval of day.intervals" 
          class="interval rows-{{interval.getDuration()}} {{interval.task?.status}}"
          (click)="onIntervalClick(day, interval)"
          [title]="interval.getTaskAddress()"          
        >
          <div class="interval__side">
            <span>{{interval.getIntervalString()}}</span>
          </div>
          <div class="interval__body">
            <span>{{interval.getTaskShortAddress()}}</span>

            <div 
              *ngIf="interval.task?.status === 'reserved' && app.isUserPartner()" 
              class="interval__delete"
              (click)="deleteTask(interval.task.id)"
            >
              <i class="fas fa-times"></i>
            </div>
          </div>
          <div *ngIf="interval.task?.newMessages.length" class="interval__notifications notifications">
            <span><i class="far fa-comment"></i></span>
            <span class="notifications__circle">{{interval.task.newMessages.length}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-task-modal [task]="currentTask" (onClientAddBtnClick)="showClientModal()" (onTaskAdd)="onTaskAdd()"></app-task-modal>
<app-client-modal (onClientAdd)="updateTaskModalData()" (onHide)="showTaskModal()"></app-client-modal>