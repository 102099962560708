import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AmoCRMService {
  public currentLeadId: BehaviorSubject<number> = new BehaviorSubject(-1);

  constructor() { }
}