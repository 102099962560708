<app-header></app-header>

<section class="task">
  <app-loading-spinner *ngIf="!isDataLoaded"></app-loading-spinner>

  <div *ngIf="isDataLoaded" class="container">
    <div *ngIf="(app.isUserManager() || app.isUserAdmin()) && data.status === 'wait'" class="alert alert-warning alert-dismissible fade show task__alert" role="alert">
      <strong>Внимание!</strong> Замерщик ждёт подтверждения проверки задания
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="task__header">
      <h1 class="task__title">{{clientData.address}}</h1>
      <div class="task__controls">
        <div *ngIf="app.isUserPartner() && data.status !== 'success' && data.status !== 'failure'" class="task__partnerControls">
          <button *ngIf="data.status !== 'wait'" type="button" class="btn btn-primary" (click)="updateStatus('wait')">Отправить на проверку</button>
          <button *ngIf="data.status === 'wait'" type="button" class="btn btn-danger" (click)="updateStatus('active')">Отменить проверку</button>
          <button *ngIf="data.status !== 'wait'" type="button" class="btn btn-secondary" (click)="editTask()">Изменить время</button>
        </div>
        <div *ngIf="app.isUserManager() || app.isUserAdmin()" class="task__managerControls">
          <div class="btn-group dropstart task__changeStatus">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" data-offset="0,5">
              Изменить статус
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li *ngIf="data.status !== 'active'">
                <button class="dropdown-item" type="button" (click)="updateStatus('active')">
                  <i class="fas fa-undo task__icon task__icon-small"></i>
                  <span>Вернуть в работу</span>
                </button>
              </li>
              <li *ngIf="data.status !== 'success'">
                <button class="dropdown-item text-success" type="button" (click)="updateStatus('success')">
                  <i class="fas fa-check task__icon task__icon-small"></i>
                  <span>Задача выполнена</span>
                </button>
              </li>
              <li *ngIf="data.status !== 'failure'">
                <button class="dropdown-item text-danger" type="button" (click)="updateStatus('failure')">
                  <i class="fas fa-times task__icon"></i>
                  <span>Задача не выполнена</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="btn-group dropstart task__settings">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-offset="0,5">
              Настройки
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li>
                <button class="dropdown-item" type="button" (click)="editTask()">
                  <i class="fas fa-pen task__icon task__icon-small"></i>
                  <span>Редактировать</span>
                </button>
              </li>
              <li>
                <button class="dropdown-item text-danger" type="button" (click)="deleteTask()">
                  <i class="fas fa-trash-alt task__icon task__icon-small"></i>
                  <span>Удалить</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>    
    </div>
    <div class="task__time">
      <i class="far fa-clock"></i>
      <span>{{data.date | toDate | date:'d MMM'}} {{data.beginTime | number:'2.0-0'}}:00 - {{data.endTime | number:'2.0-0'}}:00</span>
    </div> 
    <div class="task__info">
      <p *ngIf="manager"><span>Менеджер: </span> <a [routerLink]="'/users/' + manager.id">{{manager.getFullName()}}</a></p>
      <p *ngIf="partner"><span>Замерщик: </span> <a [routerLink]="'/users/' + partner.id">{{partner.getFullName()}}</a></p>
      <p><span>Примечание: </span> {{data.description}}</p>
    </div>

    <div class="task__client client">
      <div class="client__header">
        <p class="client__title">Информация о клиенте:</p>
        <div *ngIf="app.isUserManager() || app.isUserAdmin()" class="client__controls">
          <div class="btn-group dropstart client__settings">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-offset="0,5">
              Настройки
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li>
                <a [href]="'https://oknaklimat.amocrm.ru/leads/detail/' + clientData.amoId" target="_blank" class="dropdown-item" type="button">
                  <i class="fas fa-external-link-alt task__icon task__icon-small"></i>
                  <span>Перейти к сделке в AmoCRM</span>
                </a>
              </li>
              <li>
                <button class="dropdown-item" type="button" (click)="updateClientFromAmoCRM()">
                  <i class="fas fa-cloud-download-alt task__icon task__icon-small"></i>
                  <span>Обновить из AmoCRM</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="client__prop">ФИО: <span>{{clientData.name}} {{clientData.surname}}</span></p>
      <p class="client__prop">Номер телефона: <a [href]="'tel:' + clientData.phone">{{clientData.phone}}</a></p>
      <p class="client__prop">Адрес: 
        <a *ngIf="clientData.address !== 'Адрес не указан'" [href]="googleMapsURL.href" target="_blank">{{clientData.address}}</a>
        <span *ngIf="clientData.address === 'Адрес не указан'">{{clientData.address}}</span>
      </p>
    </div>

    <div class="task__control">
      <button class="btn btn-primary" (click)="chatMode = !chatMode">
        <i class="far fa-comments"></i> Чат с {{isUserPartner() ? 'менеджером' : 'замерщиком'}}
      </button>
    </div>

    <div *ngIf="chatMode" class="task__chat">
      <div class="task__chatBack" (click)="chatMode = !chatMode">
        <i class="fas fa-arrow-left"></i>
      </div>
      <app-chat 
        *ngIf="!!data"
        [id]="data.chatId"
        [disabled]="getChatDisabledStatus()"
      ></app-chat>
    </div>
  </div>
</section>

<app-task-modal *ngIf="isDataLoaded" [task]="data" [client]="clientData" [editMode]="true" (onTaskUpdate)="onTaskUpdate()"></app-task-modal>
<app-footer></app-footer>
