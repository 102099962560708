export class CredentialsDto {
  mail: string;
  password: string;

  constructor(options: {
    mail: string,
    password: string
  }) {
    this.mail = options.mail;
    this.password = options.password;
  }
}