import { UserDto, UserInterface } from "./user-dto";

export interface ChatMemberInterface {
  id?: number;
  chatId: number;
  userId: number;
  embedded?: {
    user?: UserInterface
  }
}

export class ChatMemberDto implements ChatMemberInterface {
  id?: number;
  chatId: number;
  userId: number;
  user?: UserDto;

  constructor(options: ChatMemberInterface) {
    this.id = options.id;
    this.chatId = options.chatId;
    this.userId = options.userId;

    if (options.embedded) {
      if (options.embedded.user) {
        this.user = new UserDto(options.embedded.user);
      }
    }
  }
}
