import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { merge, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { RESPONSE_CODE_OK } from 'src/app/app.config';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { ApiResponseDto } from 'src/app/dto/api-response-dto';
import { ClientDto } from 'src/app/dto/client-dto';
import { EventDto } from 'src/app/dto/event-dto';
import { TaskDto } from 'src/app/dto/task-dto';
import { UserDto } from 'src/app/dto/user-dto';
import { CustomDate } from 'src/app/model/custom-date';
import { AmoCRMService } from 'src/app/services/amocrm.service';
import { ClientService } from 'src/app/services/client.service';
import { EventsService } from 'src/app/services/events.service';
import { TasksService } from 'src/app/services/tasks.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manager-calendar',
  templateUrl: './manager-calendar.component.html',
  styleUrls: ['./manager-calendar.component.styl']
})
export class ManagerCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  partners: UserDto[] = [];
  tasks: TaskDto[];
  currentPartnerId: number;
  currentClientId: number;

  defaultDaysNumber: number = 6;
  beginDate: CustomDate;
  endDate: CustomDate;

  @ViewChild(CalendarComponent) private calendar: CalendarComponent;

  private alive: boolean = false;

  constructor(
    private userSvc$: UserService, 
    private taskSvc$: TasksService, 
    private changeDetectorRef: ChangeDetectorRef,
    private amocrm$: AmoCRMService,
    private clientSvc$: ClientService,
    private router: Router,
    private eventsSvc$: EventsService,
    private app: AppComponent
  ) { 
    this.updateCalendar = this.updateCalendar.bind(this);
  }

  checkAmoCRMLeadId(): void {
    if (this.amocrm$.currentLeadId.isStopped) return;

    this.amocrm$.currentLeadId.subscribe((amoLeadId: number) => {
      if (amoLeadId <= 0) return;

      this.clientSvc$.addClientFromAmoCRM(amoLeadId).subscribe((clientId: number) => {
        this.currentClientId = clientId;
      }, () => {
        this.goTask(amoLeadId);
      });
    });
  }

  goTask(amoId: number): void {
    this.taskSvc$.getTaskByAmoId(amoId).subscribe((task: TaskDto) => {
      const taskId: number = task.id;
      this.router.navigate(['/tasks/' + taskId]);
      this.amocrm$.currentLeadId.unsubscribe();
    })
  }

  onPartnerChange($event: Event): void {
    const select$: HTMLSelectElement = (<HTMLSelectElement> $event.target);
    this.currentPartnerId = parseInt(select$.value);
    this.getTasks();
  }

  getTasks(): void {
    this.tasks = null;

    if (!this.currentPartnerId) return;

    this.taskSvc$.getTasksWithNewMessages(this.currentPartnerId, this.beginDate.date, this.endDate.date, this.app.user.id).subscribe((tasks: TaskDto[]) => {
      this.tasks = tasks;
      this.calendar?.reboot();
    });
  }

  getData(): void {
    this.userSvc$.getPartners().subscribe((partners: UserDto[]) => {
      this.partners = partners;  
      this.currentPartnerId = this.partners[0]?.id;
      this.getTasks();
    });
  }

  onTaskAdd(): void {
    // this.getTasks();
    if (this.currentClientId) {
      this.currentClientId = undefined;
    }
  }

  initTaskEventsHandler(): void {
    merge(
      this.eventsSvc$.getEvents('tasks_add'), 
      this.eventsSvc$.getEvents('tasks_update'),
      this.eventsSvc$.getEvents('tasks_delete')
    ).pipe(takeWhile(e => this.alive)).subscribe((event: EventDto) => {
      if (event.type === 'tasks_add' && this.tasks.find(t => t.id === event.content.modelId)) return;

      if (event.type === 'tasks_delete') {
        this.getTasks();
        return;
      }

      this.taskSvc$.getData(event.content.modelId).subscribe((task: TaskDto) => {
        if (task.responsibleUserId === this.currentPartnerId) {
          this.getTasks();
        }
      });
    });

    this.eventsSvc$.getEvents('clients_update')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        const clientId: number = event.content.modelId;
        const index: number = this.tasks.findIndex((task: TaskDto) => task.client.id === clientId);

        if (index === -1) return;

        this.clientSvc$.getClient(clientId).subscribe((client: ClientDto) => {
          this.tasks[index].client = client;
        });
      });

    merge(
      this.eventsSvc$.getEvents('chat_messages_add'),
      this.eventsSvc$.getEvents('chat_messages_delete')
    ).pipe(takeWhile(e => this.alive)).subscribe((event: EventDto) => {
      const chatId: number = event.content.chatId;
      const index: number = this.tasks.findIndex((task: TaskDto) => task.chatId === chatId);

      if (index === -1) return;

      this.getTasks();
    });
  }

  ngOnInit(): void {
    this.getData();
    this.initTaskEventsHandler();
  }

  updateCalendar(fd: string, dates: Date[], inst: Object): void {
    if (dates.length < 2) return;
    this.beginDate = new CustomDate(dates[0]);
    this.endDate = new CustomDate(dates[1]);
    this.changeDetectorRef.detectChanges();
    
    this.getTasks();
  }

  initDatePicker(): void {
    globalThis.$('.datepicker-here').datepicker({
      range: true,
      multipleDatesSeparator: ' - ',
      toggleSelected: false,
      todayButton: new Date(),
      onSelect: this.updateCalendar
    });

    globalThis.$('.datepicker-here').data('datepicker').selectDate([
      this.beginDate.date,
      this.endDate.date
    ]);
  }

  initDateRange(): void {
    this.alive = true;
    this.beginDate = new CustomDate();
    this.endDate = new CustomDate();
    this.endDate.increase(this.defaultDaysNumber - 1);
  }

  ngAfterViewInit(): void {
    this.initDateRange();
    this.initDatePicker();
    this.checkAmoCRMLeadId();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
