import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AppComponent } from "../app.component";
import { API_BASE_URL } from "../app.config";
import { EventDto } from "../dto/event-dto";
import { NotificationDto, NotificationInterface } from "../dto/notification-dto";
import { EventsService } from "./events.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public notifications$: Subject<NotificationDto> = new Subject();
  
  constructor(
    private http$: HttpClient, 
    private eventsSvc$: EventsService
  ) {
    this.broadcastEvents();
  }

  private broadcastEvents(): void {
    this.eventsSvc$.getEvents('notifications_add').subscribe((event: EventDto) => {
      this.getNotification(event.content.modelId).subscribe((notification: NotificationDto) => {
        this.pushNotification(notification);
      });
    });
  }

  pushNotification(notification: NotificationDto): void {
    console.log("Пушим уведомление:", notification);
    this.notifications$.next(notification);
  }

  getNotification(id: number): Observable<NotificationDto> {
    const params: HttpParams = new HttpParams()
      .set('with', 'recipients');
      
    return this.http$.get<NotificationInterface>(API_BASE_URL + '/notifications/' + id, {params})
      .pipe(map(data => new NotificationDto(data)));
  }
}
