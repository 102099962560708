<div class="modal fade" tabindex="-1" id="confirmModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Подтвердите действие</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{text}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal">Отмена</button>
        <button type="button" class="btn btn-danger" (click)="confirm()">{{confirmButtonText}}</button>
      </div>
    </div>
  </div>
</div>
