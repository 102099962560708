import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { TaskDto } from 'src/app/dto/task-dto';
import { CustomDate } from 'src/app/model/custom-date';
import { ClientModalComponent } from '../client-modal/client-modal.component';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { Day } from '../../model/day';
import { Interval } from '../../model/interval';
import { ClientDto } from 'src/app/dto/client-dto';
import { TaskStatuses } from 'src/app/helpers/task-statuses.enum';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.styl']
})
export class CalendarComponent implements OnInit, OnChanges {
  days: Day[];

  @Input() currentPartnerId: number;
  @Input() tasks: TaskDto[];
  @Input() beginDate: CustomDate;
  @Input() endDate: CustomDate;
  @Input() currentClientId: number;

  @Output('onTaskAdd') taskAdd: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(TaskModalComponent) private _taskModal: TaskModalComponent;
  @ViewChild(ClientModalComponent) private _clientModal: ClientModalComponent;

  currentDate: CustomDate;
  currentTask: TaskDto = null;

  constructor(
    private router: Router, 
    private app: AppComponent,
    private taskSvc$: TasksService
  ) { }

  addDay(): void {
    if (this.days.length > 0) {
      this.currentDate = CustomDate.clone(this.currentDate);
      this.currentDate.increase();
    }

    const day: Day = new Day(this.currentDate, this.tasks);
    this.days.push(day);
  }

  initDays(): void {
    this.currentDate = this.beginDate;
    this.days = [];

    this.addDay();
    let i = 0;
    while(i < 100 && !CustomDate.equals(this.currentDate, this.endDate)) {
      i++;
      this.addDay();
    }
  }

  onIntervalClick(day: Day, interval: Interval): void {
    if (interval.task === null) {
      this.currentTask = new TaskDto();
      this.currentTask.responsibleUserId = this.currentPartnerId;
      this.currentTask.managerId = this.app.user.id;
      this.currentTask.clientId = this.currentClientId;
      this.currentTask.date = day.customDate.toString();
      this.currentTask.beginTime = interval.beginTime;
      this.currentTask.endTime = interval.endTime;
      this.currentTask.status = TaskStatuses.ACTIVE;

      if (this.app.isUserPartner()) {
        this.currentTask.description = 'занято замерщиком';
        this.currentTask.responsibleUserId = this.app.user.id;
        this.currentTask.clientId = this.currentTask.managerId = 1;
        this.currentTask.status = TaskStatuses.RESERVED;
      }

      this._taskModal.show();
    }

    if (interval.task !== null && interval.task.status !== TaskStatuses.RESERVED) {
      this.router.navigate(["/tasks/" + interval.task.id]);
    }
  }

  deleteTask(taskId: number): void {
    this.taskSvc$.deleteTask(taskId).subscribe();
  }

  showClientModal(): void {
    this._taskModal.hide();
    
    setTimeout(() => {
      this._clientModal.show();
    }, 300);
  }

  updateTaskModalData(): void {
    this._taskModal.getData();
  }

  showTaskModal(durationMs: number = 0): void {
    setTimeout(() => {
      this._taskModal.show();
    }, durationMs);
  }

  onTaskAdd(): void {
    this.taskAdd.emit();
  }

  reboot(): void {
    this.initDays();
  }

  ngOnInit(): void {
    this.reboot();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.tasks) return;
    this.reboot();
  }

}
