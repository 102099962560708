import { environment } from "src/environments/environment";

const origin = location.origin === 'http://localhost:4200' ? 'http://partners' : location.origin;
let apiURL = origin + '/backend-2';
let amoOrigin = "https://oknaklimat.amocrm.ru";
// let amoOrigin = "http://localhost:4200";

if (environment.production) {
  apiURL = "https://gayfullin.ru/backend-2";
  amoOrigin = "https://oknaklimat.amocrm.ru";
}

export const AMOCRM_ORIGIN = amoOrigin;

export const API_BASE_URL = apiURL;
export const RESPONSE_CODE_OK = 200;
export const RESPONSE_CODE_ERROR = 500;

export const POSITION_ADMIN = 1;
export const POSITION_MANAGER = 2;
export const POSITION_PARTNER = 3;

export const WORK_START_TIME = 9;
export const WORK_END_TIME = 19;