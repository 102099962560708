import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { API_BASE_URL } from "../app.config";
import { PositionDto } from "../dto/position-dto";

@Injectable({
  providedIn: 'root'
})
export class PositionsService {
  private positions: PositionDto[] = [];

  constructor(private http$: HttpClient) {}

  getPositions(): Observable<PositionDto[]> {
    if (this.positions.length === 0) {
      return this.http$.get<PositionDto[]>(API_BASE_URL + '/positions')
      .pipe(map(positions => {
        this.positions = positions.map(data => new PositionDto(data));
        return this.positions;
      }));
    }

    return of(this.positions);
  }

  getPosition(positionId: number): PositionDto | null {
    return this.positions.find(position => position.id === positionId);
  }
}
