<div class="authWrapper">
  <div class="auth">
    <div class="auth__brand">
      <svg class="auth__logo" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <g>
          <path d="M640 143.9v191.8a16 16 0 0 1-16 16h-97.6a63.36 63.36 0 0 0-22.2-37.9L358.6 195.6l26.1-23.9a16 16 0 0 0-21.6-23.6l-27 24.7-53 48.5c-.1.1-.3.1-.4.2-21.1 18.9-46.5 7.8-56.1-2.7a39.69 39.69 0 0 1 2.1-56c.1-.1.2-.3.3-.4l98.3-90a32 32 0 0 1 21.6-8.4h85.9a31.94 31.94 0 0 1 22.6 9.4L512 128h112a16 16 0 0 1 16 15.9z"></path>
          <path d="M0 335.9V144a16 16 0 0 1 16-16h112l54.7-54.6a31.94 31.94 0 0 1 22.6-9.4h83.8l-81.8 74.9a72 72 0 0 0-4.4 101.7c14.9 16.3 61.1 41.5 101.7 4.4l30-27.5 149.3 121.2a32.06 32.06 0 0 1 4.6 45.1l-9.5 11.7a32 32 0 0 1-45 4.7l-5.4-4.4-31.4 38.6a37.16 37.16 0 0 1-52.3 5.4L327 424.3l-.2.2a64 64 0 0 1-90 9.3l-90.5-81.9H16a16 16 0 0 1-16-16z"></path>
        </g>
      </svg>
      <h1 class="auth__title">Partners</h1>
    </div>

    <div *ngIf="showAlert" class="alert alert-{{alertClass}} alert-dismissible mt-3 fade show" role="alert">
      {{alertMessage}}
      <button type="button" class="close h-100" data-dismiss="alert" aria-label="Close">
        <i class="fas fa-times" style="font-size: 18px;display: block;"></i>
      </button>
    </div>

    <form [formGroup]="loginForm" *ngIf="!isSignupMode; else signupFormTemplate" class="form auth__loginForm">
      <div class="form-group">
        <label for="mail">E-Mail:</label>
        <input type="text" class="form-control" name="mail" placeholder="ivan@partners.ru" formControlName="mail">
      </div>
      <div class="form-group">
        <label for="password">Пароль:</label>
        <input type="password" class="form-control" name="password" placeholder="*****" formControlName="password">
      </div>
      <div class="form-group mt-4">
        <button type="submit" class="btn btn-primary w-100" (click)="loginUser()" [disabled]="loginForm.invalid">Войти</button>
        <a href="#" class="d-block text-center mt-2">Забыли пароль?</a>
      </div>
      <div class="form-group mt-4">
        <button class="btn btn-outline-primary w-100 mt-2" type="button" (click)="changeMode()">Зарегистрироваться</button>
      </div>
    </form>
    <ng-template #signupFormTemplate>
      <form [formGroup]="signupForm" class="form auth__signupForm">
        <div class="form-group">
          <label for="name">Имя:</label>
          <input type="text" class="form-control" name="name" placeholder="Иван" formControlName="name">
        </div>
        <div class="form-group">
          <label for="surname">Фамилия:</label>
          <input type="text" class="form-control" name="surname" placeholder="Иванов" formControlName="surname">
        </div>
        <div class="form-group">
          <label for="phone">Телефон:</label>
          <input type="text" class="form-control" name="phone" placeholder="Ivan" formControlName="phone">
        </div>
        <div class="form-group">
          <label for="mail">E-Mail:</label>
          <input type="email" class="form-control" name="mail" placeholder="ivan@partners.ru" formControlName="mail">
        </div>
        <div class="form-group">
          <label for="password">Пароль:</label>
          <input type="password" class="form-control" name="password" placeholder="*****" formControlName="password">
        </div>
        <div class="form-group">
          <label for="position">Роль:</label>
          <select name="position" class="form-control" formControlName="position">
            <option *ngFor="let position of positions" [value]="position.id">{{position.name}}</option>
          </select>
        </div>
        <!-- <div class="form-group">
          <label class="text-bold" for="passwordConfirm">Подтверждение пароля*:</label>
          <input type="password" class="form-control" name="passwordConfirm" placeholder="*****" formControlName="passwordConfirm">
        </div> -->
        <div class="form-group mt-4">
          <button type="submit" class="btn btn-primary w-100" (click)="signupUser()" [disabled]="signupForm.invalid">Зарегистрироваться</button>
        </div>
        <div class="form-group mt-4">
          <p class="text-center mb-2">Уже есть аккаунт?</p>
          <button class="btn btn-outline-primary w-100" type="button" (click)="changeMode()">Войти</button>
        </div>
      </form>
    </ng-template>
  </div>
</div>
