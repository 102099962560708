import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_BASE_URL } from "src/app/app.config";
import { ChatMemberDto, ChatMemberInterface } from "src/app/dto/chat-member-dto";
import { ChatMessageDto, ChatMessageInterface } from "src/app/dto/chat-message-dto";

@Injectable()
export class ChatService {
  constructor(private http$: HttpClient) {}

  deleteMessage(message: ChatMessageDto): Observable<void> {
    return this.http$.delete<void>(API_BASE_URL + '/chat-messages/' + message.id);
  }

  updateMessage(message: ChatMessageDto): Observable<void> {
    return this.http$.put<void>(API_BASE_URL + '/chat-messages/' + message.id, message);
  }

  getMembers(chatId: number): Observable<ChatMemberDto[]> {
    const params: HttpParams = new HttpParams()
      .set('chatId', chatId.toString())
      .set('with', 'user');

    return this.http$.get<ChatMemberInterface[]>(API_BASE_URL + '/chat-members', {params})
      .pipe(map(members => members.map(data => new ChatMemberDto(data))));
  }

  addMember(member: ChatMemberDto): Observable<number> {
    return this.http$.post<number>(API_BASE_URL + '/chat-members', member);
  }

  getMessage(id: number): Observable<ChatMessageDto> {
    const params: HttpParams = new HttpParams()
      .set("with", "attachments");

    return this.http$.get<ChatMessageInterface>(API_BASE_URL + '/chat-messages/' + id, {params})
      .pipe(map(data => new ChatMessageDto(data)));
  }

  getMessages(chatId: number): Observable<ChatMessageDto[]> {
    const params: HttpParams = new HttpParams()
      .set("chatId", chatId.toString());

    return this.http$.get<ChatMessageInterface[]>(API_BASE_URL + '/chat-messages', {params})
      .pipe(map(messages => messages.map(data => new ChatMessageDto(data))));
  }

  sendMessage(message: ChatMessageDto): Observable<number> {
    return this.http$.post<number>(API_BASE_URL + '/chat-messages', message);
  }
}
