<div *ngIf="isDataLoaded" class="modal fade" tabindex="-1" id="taskModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{getModalTitle()}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" class="form">
          <div *ngIf="!app.isUserPartner()" class="form-group">
            <label for="date">Исполнитель:</label>
            <select 
              name="responsibleUserId" 
              class="form-control" 
              formControlName="responsibleUserId" 
              [disableControl]="currentTask?.responsibleUserId && !editMode"
              (change)="onPartnerChange($event)">
              <option *ngFor="let partner of partners" [value]="partner.id">{{partner.name + ' ' + partner.surname}}</option>
            </select>
            <div class="invalid-feedback" [class.show]="form.get('responsibleUserId').touched && form.get('responsibleUserId').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div *ngIf="!app.isUserPartner()" class="form-group">
            <label for="date">Клиент:</label>
            <div class="form-inline taskForm__inline">
              <div class="form-group taskForm__inlineClient">
                <select name="clientId" class="form-control" formControlName="clientId" [disableControl]="currentTask?.clientId && !editMode">
                  <option *ngFor="let client of clients" [value]="client.id">{{client.name + ' ' + client.surname + ' (AmoCRM: ' + client.amoId + ')'}}</option>
                </select>
              </div>
              <button type="button" class="btn btn-primary ml-0 ml-sm-2" (click)="onClientAdd()" [hidden]="currentTask?.clientId">Добавить</button>
            </div>
            <div class="invalid-feedback" [class.show]="form.get('clientId').touched && form.get('clientId').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div *ngIf="!app.isUserPartner()" class="form-group">
            <label for="description">Примечание:</label>
            <textarea class="form-control" name="description" cols="30" rows="4" placeholder="Укажите важные детали задачи для исполнителя..." formControlName="description"></textarea>
            <div class="invalid-feedback" [class.show]="form.get('description').touched && form.get('description').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div class="form-group">
            <label for="date">Дата:</label>
            <input type="date" class="form-control" name="date" formControlName="date" (change)="onDateChange($event)">
            <div class="invalid-feedback" [class.show]="form.get('date').touched && form.get('date').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div class="form-group">
            <label for="date">Время:</label>
            <div class="form-inline taskForm__inline">
              <div class="form-group">
                <select name="beginTime" class="form-control" formControlName="beginTime" (change)="onBeginTimeChange($event)">
                  <option *ngFor="let time of beginTimes" [value]="time">{{time | number:'2.0-0'}}:00</option>
                </select>
              </div>
              <i class="fas fa-minus"></i>
              <div class="form-group">
                <select #endTime name="endTime" class="form-control" formControlName="endTime">
                  <option *ngFor="let time of filteredEndTimes" [value]="time">{{time | number:'2.0-0'}}:00</option>
                </select>
              </div>
            </div>
            <div class="invalid-feedback" [class.show]="form.get('beginTime').touched && form.get('beginTime').invalid || form.get('endTime').touched && form.get('endTime').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
        <button type="button" class="btn btn-primary" (click)="onFormSubmit()" [disabled]="form.invalid">Сохранить</button>
      </div>
    </div>
  </div>
</div>