import { TaskStatuses } from "../helpers/task-statuses.enum";
import { ChatMessageDto, ChatMessageInterface } from "./chat-message-dto";
import { ClientDto, ClientInterface } from "./client-dto";
import { UserDto, UserInterface } from "./user-dto";

export interface TaskInterface {
  id?: number;
  description: string;
  cost: number;
  date: string;
  beginTime: number;
  endTime: number;
  status: TaskStatuses;
  closedAt: number;
  clientId: number;
  responsibleUserId: number;
  managerId: number;
  chatId: number;
  embedded?: {
    client?: ClientInterface,
    responsibleUser?: UserInterface,
    manager?: UserInterface,
    newMessages?: ChatMessageInterface[]
  }
}

export class TaskDto {
  id?: number;
  description: string;
  cost: number;
  date: string;
  beginTime: number;
  endTime: number;
  status: TaskStatuses;
  closedAt: number;
  clientId: number;
  responsibleUserId: number;
  managerId: number;
  chatId: number;

  client?: ClientDto;
  responsibleUser?: UserDto;
  manager?: UserDto;
  newMessages?: ChatMessageInterface[];

  constructor(options: TaskInterface = {
    description: "",
    cost: 0,
    date: new Date().toString(),
    beginTime: 9,
    endTime: 10,
    status: TaskStatuses.ACTIVE,
    closedAt: 0,
    clientId: 0,
    responsibleUserId: 0,
    managerId: 0,
    chatId: 0
  }) {
    this.id = options.id;
    this.description = options.description;
    this.cost = options.cost;
    this.date = options.date;
    this.beginTime = options.beginTime;
    this.endTime = options.endTime;
    this.status = options.status;
    this.closedAt = options.closedAt;
    this.clientId = options.clientId;
    this.responsibleUserId = options.responsibleUserId;
    this.managerId = options.managerId;
    this.chatId = options.chatId;
    
    if (options.embedded) {
      if (options.embedded.client) {
        this.client = new ClientDto(options.embedded.client);
      }
      if (options.embedded.responsibleUser) {
        this.responsibleUser = new UserDto(options.embedded.responsibleUser);
      }
      if (options.embedded.manager) {
        this.manager = new UserDto(options.embedded.manager);
      }
      if (options.embedded.newMessages) {
        this.newMessages = options.embedded.newMessages.map(data => new ChatMessageDto(data));
      }
    }
  }

  includes(value: string): boolean {
    return JSON.stringify(this).toLowerCase().includes(value.toLowerCase());
  }
}
