<app-header></app-header>

<section class="section tasks">
  <div class="container">
    <div class="tasks__header">
      <h1 class="title tasks__title">Мои задачи:</h1>
      <!-- <div class="tasks__view view">
        <p class="view__text">Отображение:</p>
        <i class="far fa-calendar-alt view__item" [class.active]="viewType === 'calendar'" title="Отобразить задачи в виде календаря" (click)="changeViewType('calendar')"></i>
        <i class="fas fa-bars view__item" [class.active]="viewType === 'list'" title="Отобразить задачи списком" (click)="changeViewType('list')"></i>
      </div> -->
      <div class="tasks__controls">
        <div class="tasks__control">
          <span>Диапазон дат:</span>
          <input type="text" class="datepicker-here form-control"/>
        </div>
      </div>
    </div>

    <app-calendar 
      *ngIf="viewType === 'calendar' && !!tasks" 
      [tasks]="tasks" 
      [beginDate]="beginDate"
      [endDate]="endDate"
    ></app-calendar>

    <div class="tasks__list" *ngIf="viewType === 'list'">

      <div class="task" *ngFor="let task of tasks.reverse()" [routerLink]="'/tasks/' + task.id">
        <div class="task__header">
          <p class="task__title">{{task.title}}</p>
          <div class="task__attributes attributes">
            <div class="attributes__item">
              <i class="far fa-clock"></i>
              <span>{{task.date | toDate | date:'d MMM'}} {{task.beginTime | number:'2.0-0'}}:00 - {{task.endTime | number:'2.0-0'}}:00</span>
            </div>
            <!-- <div class="attributes__item">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ruble-sign" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z" class=""></path></svg>
              <span>{{task.cost}}</span>
            </div> -->
          </div>
        </div>
        <div class="task__body">
          <p>{{task.description}}</p>
        </div>
      </div>

    </div>


  </div>
</section>

<app-footer></app-footer>