import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl'],
  animations: [
    trigger('sidebar', [
      state('hide', style({ left: '-300px' })),
      state('show', style({ left: '0px' })),
      transition('hide <=> show', animate('0.3s')),
    ]),
    trigger('sidebarWrapper', [
      state('hide', style({ zIndex: -1, opacity: 0 })),
      state('show', style({ zIndex: 10, opacity: 1 })),
      transition('hide <=> show', animate('0.3s')),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  @ViewChild('sidebar') sidebarRef: ElementRef<HTMLDivElement>;
  @ViewChild('sidebarWrapper') sidebarWrapperRef: ElementRef<HTMLDivElement>;

  sidebarIsVisible: boolean = false;
  sidebarState: string = "hide";

  isSearchMode: boolean = false;

  constructor(public app: AppComponent) { }

  toggleSidebar(): void {
    this.sidebarIsVisible = !this.sidebarIsVisible;
    this.sidebarState = this.sidebarIsVisible ? "show" : "hide";
  }

  ngOnInit(): void {
  }

}
