import { Pipe, PipeTransform } from '@angular/core';
  
@Pipe({
    name: 'remainingTime'
})
export class RemainingTimePipe implements PipeTransform {
  transform(seconds: number): string {
    const currentSecconds: number = Math.floor(Date.now() / 1000);
    let offset: number = Math.max(seconds - currentSecconds, 0);

    const daySec: number = 24 * 60 * 60;
    const hourSec: number = 60 * 60;
    const minSec: number = 60;

    const days: number = Math.floor(offset / daySec);
    const hours: number = Math.floor((offset - days * daySec) / hourSec);
    const mins: number = Math.floor((offset - days * daySec - hours * hourSec) / minSec);

    let result: string = "";

    if (days > 0) result += days + "д ";
    if (hours > 0) result += hours + "ч ";
    if (mins >= 0) result += mins + "м";

    return result;
  }
}