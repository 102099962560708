import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.prod';

import firebase from 'firebase';
import 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDwNTMSSbDeZMiNz8whq1L-8AlSvku5u-o",
  authDomain: "partners-app-2021.firebaseapp.com",
  projectId: "partners-app-2021",
  storageBucket: "partners-app-2021.appspot.com",
  messagingSenderId: "350825204651",
  appId: "1:350825204651:web:0d8b37355a4176f45e60ee",
  measurementId: "G-NZSZQ5JY5F"
};

firebase.initializeApp(firebaseConfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
