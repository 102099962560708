<app-header></app-header>

<section class="section managerCalendar">
  <div class="container">
    <div class="managerCalendar__header">
      <h1 class="title managerCalendar__title">Календарь:</h1>
      <div class="managerCalendar__controls">
        <div *ngIf="partners.length" class="managerCalendar__control">
          <span>Замерщик:</span>
          <select class="form-control" (change)="onPartnerChange($event)">
            <option *ngFor="let partner of partners; let i = index" [value]="partner.id" [checked]="i === 0">{{partner.name}} {{partner.surname}}</option>
          </select>
        </div>
        <div class="managerCalendar__control">
          <span>Диапазон дат:</span>
          <input type="text" class="datepicker-here form-control"/>
        </div>
      </div>
    </div>

    <app-calendar 
      *ngIf="!!tasks" 
      [tasks]="tasks" 
      [partners]="partners" 
      [currentPartnerId]="currentPartnerId"
      [currentClientId]="currentClientId"
      [beginDate]="beginDate"
      [endDate]="endDate"
      (onTaskAdd)="onTaskAdd()" 
    ></app-calendar>
  </div>
</section>

<app-footer></app-footer>