import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NotificationDto } from 'src/app/dto/notification-dto';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-live-toasts',
  templateUrl: './live-toasts.component.html',
  styleUrls: ['./live-toasts.component.styl']
})
export class LiveToastsComponent implements OnInit {
  notifications: NotificationDto[] = [];

  constructor(
    private notificationsSvc$: NotificationsService, 
    private cdr: ChangeDetectorRef,
    private app: AppComponent,
    private router: Router
  ) { }

  afterHideDelete(notification: NotificationDto): void {
    setTimeout(() => {
      const index: number = this.notifications.findIndex(n => n.id === notification.id);
      if (index === -1) return;
      this.notifications.splice(index, 1);
      this.cdr.detectChanges();
    }, 4000);
  }

  initNotificationEventsHandler(): void {
    this.notificationsSvc$.notifications$.subscribe((notification: NotificationDto) => {
      if (!notification.isSystem && (!notification.recipients?.find(r => r.userId === this.app.user.id) || notification.link === this.router.url)) return;
      this.notifications.push(notification);
      this.cdr.detectChanges();
      this.afterHideDelete(notification);
    });
  }

  ngOnInit(): void {
    this.initNotificationEventsHandler();
  }

}
