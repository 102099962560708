import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { TasksComponent } from './pages/tasks/tasks.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TaskComponent } from './pages/tasks/task/task.component';
import { RemainingTimePipe } from './pipes/remaining-time.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { UsersComponent } from './pages/users/users.component';
import { UserComponent } from './pages/users/user/user.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { AuthComponent } from './pages/auth/auth.component';
import { ToDatePipe } from './pipes/to-date.pipe';
import { ManagerCalendarComponent } from './pages/manager-calendar/manager-calendar.component';
import { TaskModalComponent } from './components/task-modal/task-modal.component';
import { ClientModalComponent } from './components/client-modal/client-modal.component';
import { ToastComponent } from './components/live-toasts/toast/toast.component';
import { UserDeactivatedComponent } from './pages/user-deactivated/user-deactivated.component';
import { ChatComponent } from './components/chat/chat.component';
import { HelpComponent } from './pages/help/help.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { ModalComponent } from './components/modal/modal.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LiveToastsComponent } from './components/live-toasts/live-toasts.component';
import { UploadFileComponent } from './components/chat/upload-file/upload-file.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    TasksComponent,
    HeaderComponent,
    FooterComponent,
    TaskComponent,
    RemainingTimePipe,
    CustomDatePipe,
    ToDatePipe,
    UsersComponent,
    UserComponent,
    CalendarComponent,
    ManagerCalendarComponent,
    TaskModalComponent,
    ClientModalComponent,
    ToastComponent,
    UserDeactivatedComponent,
    ChatComponent,
    HelpComponent,
    DisableControlDirective,
    ModalComponent,
    LoadingSpinnerComponent,
    LiveToastsComponent,
    UploadFileComponent,
    SearchFormComponent,
    ConfirmModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'ru-RU'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
