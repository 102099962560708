import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TaskDto } from 'src/app/dto/task-dto';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.styl']
})
export class SearchFormComponent implements OnInit {
  public searchValue: string = '';
  public tasks: TaskDto[] = [];
  public filteredTasks: TaskDto[] = [];
  
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  
  @Output('closeReason') closeReason: EventEmitter<void> = new EventEmitter();

  constructor(private svc$: TasksService, private router: Router) { }

  taskClick(task: TaskDto): void {
    this.router.navigate(['/tasks/' + task.id]).then(() => {
      this.close();
    });
  }

  searchValueChange(): void {
    this.searchValue = this.searchInput.nativeElement.value;
    this.filterTasks();

    if (this.searchValue.length > 0) {
      this.showResults();
    } else {
      this.hideResults();
    }
  }

  showResults(): void {
    globalThis.$('#searchFormResult').collapse('show');
  }

  hideResults(): void {
    globalThis.$('#searchFormResult').collapse('hide');
  }

  close(): void {
    this.closeReason.emit();
  }

  filterTasks(): void {
    this.filteredTasks = this.tasks.filter(task => task.includes(this.searchValue));
  }

  getTasks(): void {
    this.svc$.getTasks().subscribe(tasks => {
      this.tasks = tasks;
    });
  }

  ngOnInit(): void {
    this.getTasks();
  }

  @HostListener('document:click', ['$event']) 
  closeResultBlockReason(event: PointerEvent): void {
    const target: HTMLElement = <HTMLElement> event.target;

    if (!target.closest('.serachForm')) {
      this.hideResults();
    }
  }

}
