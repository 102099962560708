export class AmoCRMWindowMessageDto {
  type: "LEAD_ID";
  data: any;

  constructor(options: {
    type: "LEAD_ID",
    data: any
  }) {
    this.type = options.type;
    this.data = options.data;
  }
}