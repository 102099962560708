export interface AttachmentInterface {
  id?: number;
  source?: string;
  uploadedAt?: number;
  messageId?: number;
}

export class AttachmentDto {
  id?: number;
  source?: string;
  uploadedAt?: number;
  messageId?: number;

  constructor(options: {
    id?: number,
    source?: string,
    uploadedAt?: number,
    messageId?: number
  } = {}) {
    this.id = options.id;
    this.source = options.source;
    this.uploadedAt = options.uploadedAt;
    this.messageId = options.messageId;
  }
}