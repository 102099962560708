import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl']
})
export class ModalComponent implements OnInit, AfterViewInit {
  private $elem: any;
  private hash: string;
  public elemId: string;

  @Input() title: string = "Без названия";

  constructor() { }

  show(): void {
    this.$elem.modal('show');
  }

  hide(): void {
    this.$elem.modal('hide');
  }

  ngOnInit(): void {
    this.hash = this.title.length.toString() + Date.now();
    this.elemId = "modal_" + this.hash;
  }

  ngAfterViewInit(): void {
    this.$elem = globalThis.$('#' + this.elemId);
  }

}
