import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { POSITION_ADMIN, POSITION_MANAGER, POSITION_PARTNER, RESPONSE_CODE_OK } from 'src/app/app.config';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { ApiResponseDto } from 'src/app/dto/api-response-dto';
import { PositionDto } from 'src/app/dto/position-dto';
import { UserDto } from 'src/app/dto/user-dto';
import { PositionsService } from 'src/app/services/positions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.styl']
})
export class UsersComponent implements OnInit {
  positions: PositionDto[];
  users: UserDto[];

  userForDelete: UserDto = null;

  @ViewChild(ConfirmModalComponent) confirmModal: ConfirmModalComponent;

  constructor(
    public app: AppComponent,
    public positionsSvc$: PositionsService,
    private router: Router,
    private svc$: UserService,
    private usersSvc$: UserService
  ) { }

  deleteUserReason($event: Event, user: UserDto): void {
    $event.stopPropagation();
    this.userForDelete = user;
    this.confirmModal.text = `Вы действительно хотите удалить этого пользователя? (${this.userForDelete.getFullName()})`;
    this.confirmModal.show();
  }

  deleteUser(): void {
    this.usersSvc$.deleteUser(this.userForDelete?.id).subscribe(() => {
      this.getUsers();
    });
  }

  getPositionName(positionId: number): string {
    return this.positionsSvc$.getPosition(positionId)?.name;
  }

  checkRole(): void {
    if (!this.app.isUserAdmin()) {
      this.router.navigate(['/']);
    }
  }

  getStatusClass(isActive: boolean): string {
    return isActive ? "text-success" : "text-danger text-bold";
  }

  getStatusName(isActive: boolean): string {
    return isActive ? "активирован" : "не активирован";
  }

  activateUser($event: Event, user: UserDto): void {
    $event.stopPropagation();
    
    user.isActive = true;

    this.svc$.updateUser(user).subscribe(() => {
      this.getUsers();
      this.app.showToast("Пользователи", "Пользователь успешно активирован!");
    });
  }

  deactivateUser($event: Event, user: UserDto): void {
    $event.stopPropagation();

    user.isActive = false;

    this.svc$.updateUser(user).subscribe(() => {
      this.getUsers();
      this.app.showToast("Пользователи", "Пользователь успешно деактивирован!");
    });
  }

  getUsers(): void {
    this.svc$.getUsers().subscribe((users: UserDto[]) => {
      this.users = users;
    });
  }

  ngOnInit(): void {
    this.checkRole();
    this.getUsers();
  }

}
