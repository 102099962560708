import { AttachmentDto, AttachmentInterface } from "./attachment-dto";

export interface ChatMessageInterface {
  id?: number;
  text: string;
  sentAt?: number;
  updatedAt?: number;
  isRead?: boolean;
  memberId: number;
  embedded?: {
    attachments?: AttachmentInterface[]
  }
}

export class ChatMessageDto implements ChatMessageInterface {
  id?: number;
  text: string;
  sentAt?: number;
  updatedAt?: number;
  isRead?: boolean;
  memberId: number;

  attachments?: AttachmentDto[] = [];

  // Используется только на фронте
  #isSelected: boolean = false;

  constructor(options: ChatMessageInterface) {
    this.id = options.id;
    this.text = options.text;
    this.sentAt = options.sentAt ?? Math.floor(Date.now() / 1000);
    this.updatedAt = options.updatedAt ?? null;
    this.isRead = options.isRead ?? false;
    this.memberId = options.memberId;
    
    if (options.embedded) {
      if (options.embedded.attachments) {
        this.attachments = options.embedded.attachments.map(data => new AttachmentDto(data));
      }
    }
  }

  public setIsSelected(isSelected: boolean) {
    this.#isSelected = isSelected;
  }

  public getIsSelected(): boolean {
    return this.#isSelected;
  }

  public isUpdated(): boolean {
    return !!this.updatedAt;
  }

  public getDate(): Date {
    return new Date(this.sentAt * 1000);
  }

  public static equals(first: ChatMessageDto, second: ChatMessageDto): boolean {
    if (JSON.stringify(first) !== JSON.stringify(second)) {
      console.log(JSON.stringify(first), JSON.stringify(second));
    }

    return JSON.stringify(first) === JSON.stringify(second);
  }
}
