import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, pipe } from "rxjs";
import { delay, map } from "rxjs/operators";
import { API_BASE_URL } from "src/app/app.config";
import { AttachmentDto, AttachmentInterface } from "../dto/attachment-dto";

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {
  constructor(private http$: HttpClient) {}

  updateAttachments(attachments: AttachmentDto[]): Observable<void> {
    return this.http$.put<void>(API_BASE_URL + '/attachments', attachments);
  }

  updateAttachment(attachment: AttachmentDto): Observable<void> {
    return this.http$.put<void>(API_BASE_URL + '/attachments/' + attachment.id, attachment);
  }
  
  deleteAttachment(id: number): Observable<void> {
    return this.http$.delete<void>(API_BASE_URL + '/attachments/' + id);
  }
  
  getAttachment(id: number): Observable<AttachmentDto> {
    return this.http$.get<AttachmentInterface>(API_BASE_URL + '/attachments/' + id)
      .pipe(map(data => new AttachmentDto(data)));
  }

  uploadFile(file: File, messageId: number = null): Observable<AttachmentDto> {
    return this.uploadFiles([file], messageId)
      .pipe(map(attachments => attachments[0]));
  }

  uploadFiles(files: File[], messageId: number = null): Observable<AttachmentDto[]> {
    const data: FormData = new FormData();

    if (messageId !== null) {
      data.append("messageId", messageId.toString());
    }
    
    files.forEach((file: File, index: number) => {
      data.append("file_" + index, file, file.name);
    });

    return this.http$.post<AttachmentInterface[]>(API_BASE_URL + '/attachments', data)
      .pipe(map(attachments => attachments.map(data => new AttachmentDto(data))));
  }
}
