import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { RESPONSE_CODE_OK } from 'src/app/app.config';
import { ApiResponseDto } from 'src/app/dto/api-response-dto';
import { ClientDto } from 'src/app/dto/client-dto';
import { ToastComponent } from '../live-toasts/toast/toast.component';
import { ClientService } from '../../services/client.service';
import { NotificationDto } from 'src/app/dto/notification-dto';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.styl']
})
export class ClientModalComponent implements OnInit {
  form: FormGroup;

  @Output() onClientAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onHide: EventEmitter<any> = new EventEmitter<any>();

  constructor(private svc$: ClientService, private app: AppComponent, private notificationsSvc$: NotificationsService) { }

  show(): void {
    globalThis.$('#clientModal').modal('show');
  }

  hide(): void {
    globalThis.$('#clientModal').modal('hide');
    this.onHide.emit();
  }

  showToast(text: string): void {
    this.notificationsSvc$.pushNotification(new NotificationDto({
      title: "Клиенты",
      text: text,
      createdAt: Math.floor(Date.now() / 1000),
      isSystem: true
    }));
  }

  initForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      surname: new FormControl(''),
      phone: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      amoId: new FormControl('', Validators.required)
    });
  }

  addClient(): void {
    const client: ClientDto = new ClientDto(this.form.value);

    this.svc$.addClient(client).subscribe(() => {
      this.hide();
      this.app.showToast("Клиенты", "Клиент успешно добавлен!");
      this.onClientAdd.emit();
    });
  }

  ngOnInit(): void {
    this.initForm();
  }
}
