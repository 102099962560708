<div class="p-3" [routerLink]="link">
  <div [id]="getId()" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="3000">
    <div class="toast-header">
      <i class="fas fa-bell"></i>
      <strong class="ml-1 mr-auto">{{!isError ? title : "Ошибка!"}}</strong>
      <small>{{date | date:'HH:mm'}}</small>
      <button type="button" class="close" data-dismiss="toast" aria-label="Close" (click)="hide($event)">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="toast-body">
      {{text}}
    </div>
  </div>
</div>