import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-user-deactivated',
  templateUrl: './user-deactivated.component.html',
  styleUrls: ['./user-deactivated.component.styl']
})
export class UserDeactivatedComponent implements OnInit {

  constructor(private router: Router, private app: AppComponent) { }

  checkUserActivate(): void {
    if (this.app.isUserActive()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.checkUserActivate();
  }

}
