<header class="header">
  <div class="container header__container">
    <div class="header__burger" [class.hidden-mobile]="isSearchMode" (click)="toggleSidebar()">
      <i class="fas fa-bars"></i>
    </div>
    <a routerLink="/" class="header__brand headerBrand" [class.hidden-mobile]="isSearchMode">
      <i class="fas fa-handshake headerBrand__logo"></i>
      <p class="headerBrand__name">Partners</p>
    </a>
    <div *ngIf="isSearchMode" class="header__searchForm">
      <app-search-form (closeReason)="isSearchMode = false"></app-search-form>
    </div>
    <div class="header__main" #sidebar [@sidebar]="sidebarState">
      <div *ngIf="!isSearchMode" class="header__menu menu">
        <a *ngIf="app.isUserPartner()" routerLink="/tasks" routerLinkActive="active" class="menu__item">Список задач</a>
        <a *ngIf="app.isUserAdmin() || app.isUserManager()" routerLink="/calendar" routerLinkActive="active" class="menu__item">Календарь</a>
        <a *ngIf="app.isUserAdmin()" routerLink="/users" routerLinkActive="active" class="menu__item">Пользователи</a>
      </div>
      <div class="header__profile headerProfile">
        <!-- <a routerLink="/profile" class="headerProfile__item">
          <i class="fas fa-user-alt"></i>&nbsp;<span>Профиль</span>
        </a> -->
        <a *ngIf="!isSearchMode" class="headerProfile__item" title="Поиск по задачам" (click)="isSearchMode = true">
          <i class="fas fa-search"></i>&nbsp;<span>Найти задачу</span>
        </a>
        <a routerLink="/help" class="headerProfile__item text-info" routerLinkActive="active" title="Техническая поддержка">
          <i class="far fa-question-circle"></i>&nbsp;<span>Нужна помощь</span>
        </a>
        <a routerLink="/auth/logout" class="headerProfile__item text-danger" title="Выйти из аккаунта">
          <i class="fas fa-door-open"></i>&nbsp;<span>Выход</span>
        </a>
      </div>
    </div>
    <div *ngIf="!isSearchMode" class="header__right" (click)="isSearchMode = true">
      <i class="fas fa-search"></i>
    </div>
  </div>
</header>

<div #sidebarWrapper class="mobileSidebarBg" (click)="toggleSidebar()" [@sidebarWrapper]="sidebarState"></div>