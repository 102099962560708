import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AttachmentDto } from 'src/app/dto/attachment-dto';
import { AttachmentsService } from 'src/app/services/attachments.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.styl']
})
export class UploadFileComponent implements OnInit, AfterViewInit {
  @Input() file: File;

  @Output('uploaded') onUploaded: EventEmitter<AttachmentDto> = new EventEmitter();
  @Output('removed') onRemoved: EventEmitter<void> = new EventEmitter();

  private attachment: AttachmentDto;
  private uploadSubscription: Subscription;

  public status: 'success' | 'uploading' | 'removed';

  @ViewChild('progressBar') progressBarRef: ElementRef<HTMLDivElement>;

  constructor(private attachmentsSvc$: AttachmentsService) { }

  setProgressWidth(px: number): void {
    if (!this.progressBarRef) return;
    this.progressBarRef.nativeElement.style.width = px + 'px';
  }

  startProgressAnimation(): void {
    let i = 0;

    const interval = setInterval(() => {
      if (i > 90) clearInterval(interval);
      this.setProgressWidth(i);
      i += 2;
    }, 50);
  }

  upload(): void {
    this.status = 'uploading';

    this.uploadSubscription = this.attachmentsSvc$.uploadFile(this.file).subscribe(attachment => {
      this.attachment = attachment;
      this.onUploaded.emit(attachment);

      this.setProgressWidth(100);

      setTimeout(() => {
        this.status = 'success';
      }, 500);
    });
  }

  remove(): void {
    if (this.status === 'uploading') {
      this.uploadSubscription.unsubscribe();
    }

    if (this.status === 'success') {
      this.attachmentsSvc$.deleteAttachment(this.attachment.id).subscribe();
    }

    this.status = 'removed';
    this.onRemoved.emit();
  }

  ngOnInit(): void {
    this.upload();
  }

  ngAfterViewInit(): void {
    this.startProgressAnimation();
  }

}
