import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { RESPONSE_CODE_OK } from 'src/app/app.config';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { ApiResponseDto } from 'src/app/dto/api-response-dto';
import { ClientDto } from 'src/app/dto/client-dto';
import { EventDto } from 'src/app/dto/event-dto';
import { TaskDto } from 'src/app/dto/task-dto';
import { CustomDate } from 'src/app/model/custom-date';
import { ClientService } from 'src/app/services/client.service';
import { EventsService } from 'src/app/services/events.service';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-home',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.styl']
})
export class TasksComponent implements OnInit, AfterViewInit, OnDestroy {
  tasks: TaskDto[];
  viewType: string = "calendar";

  defaultDaysNumber: number = 6;
  beginDate: CustomDate;
  endDate: CustomDate;

  @ViewChild(CalendarComponent) private calendar: CalendarComponent;

  private alive: boolean = false;

  constructor(
    private svc$: TasksService, 
    private app: AppComponent, 
    private changeDetectorRef: ChangeDetectorRef,
    private eventsSvc$: EventsService,
    private taskSvc$: TasksService,
    private clientSvc$: ClientService
  ) { 
    this.updateCalendar = this.updateCalendar.bind(this);
  }

  changeViewType(type: string): void {
    this.viewType = type;
  }

  initTaskEventsHandler(): void {
    merge(
      this.eventsSvc$.getEvents('tasks_add').pipe(takeWhile(e => this.alive)), 
      this.eventsSvc$.getEvents('tasks_update').pipe(takeWhile(e => this.alive)),
      this.eventsSvc$.getEvents('tasks_delete').pipe(takeWhile(e => this.alive))
    ).subscribe((event: EventDto) => {
      if (event.type === 'tasks_add' && this.tasks.find(t => t.id === event.content.modelId)) return;

      if (event.type === 'tasks_delete') {
        this.getTasks();
        return;
      }

      this.taskSvc$.getData(event.content.modelId).subscribe((task: TaskDto) => {
        if (task.responsibleUserId === this.app.user.id) {
          this.getTasks();
        }
      });
    });

    this.eventsSvc$.getEvents('clients_update')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        const clientId: number = event.content.modelId;
        const index: number = this.tasks.findIndex((task: TaskDto) => task.client.id === clientId);

        if (index === -1) return;

        this.clientSvc$.getClient(clientId).subscribe((client: ClientDto) => {
          this.tasks[index].client = client;
        });
      });
  }

  getTasks(): void {
    this.svc$.getTasksWithNewMessages(this.app.user.id, this.beginDate?.date, this.endDate?.date, this.app.user.id).subscribe((tasks: TaskDto[]) => {
      this.tasks = tasks;
    });
  }

  ngOnInit(): void {
    this.alive = true;
    this.getTasks();
    this.initTaskEventsHandler();
  }

  updateCalendar(fd: string, dates: Date[], inst: Object): void {
    if (dates.length < 2) return;
    this.beginDate = new CustomDate(dates[0]);
    this.endDate = new CustomDate(dates[1]);
    this.changeDetectorRef.detectChanges();
    this.calendar?.reboot();
  }

  initDatePicker(): void {
    globalThis.$('.datepicker-here').datepicker({
      range: true,
      multipleDatesSeparator: ' - ',
      toggleSelected: false,
      todayButton: new Date(),
      onSelect: this.updateCalendar
    });

    globalThis.$('.datepicker-here').data('datepicker').selectDate([
      this.beginDate.date,
      this.endDate.date
    ]);
  }

  initDateRange(): void {
    this.beginDate = new CustomDate();
    this.endDate = new CustomDate();
    this.endDate.increase(this.defaultDaysNumber - 1);
  }

  ngAfterViewInit(): void {
    this.initDateRange();
    this.initDatePicker();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
