export interface UserInterface {
  id?: number;
  name?: string;
  surname?: string;
  phone?: string;
  mail?: string;
  password?: string;
  isActive?: boolean;
  positionId?: number;
  fcmToken?: string;
}

export class UserDto {
  id?: number;
  name?: string;
  surname?: string;
  phone?: string;
  mail?: string;
  password?: string;
  isActive?: boolean;
  positionId?: number;
  fcmToken?: string;

  constructor(options: UserInterface = {}) {
    this.id = options.id;
    this.name = options.name;
    this.surname = options.surname;
    this.phone = options.phone;
    this.mail = options.mail;
    this.password = options.password;
    this.isActive = options.isActive;
    this.positionId = options.positionId;
    this.fcmToken = options.fcmToken;
  }

  getFullName(): string {
    return this.name + ' ' + this.surname;
  }

  getPhone(): string {
    const digits: string[] = this.phone.match(/\d/g).reverse().slice(0, 10).reverse();
    return '+7' + digits.join('');
  }
}
