import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_BASE_URL } from "../app.config";
import { UserDto } from "../dto/user-dto";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http$: HttpClient) {}

  getUsers(params?: HttpParams): Observable<UserDto[]> {
    if (!params) params = new HttpParams();

    return this.http$.get<UserDto[]>(API_BASE_URL + '/users', {params})
      .pipe(map(users => users.map(data => new UserDto(data))));
  }

  getUser(userId: number): Observable<UserDto> {
    return this.http$.get<UserDto>(API_BASE_URL + '/users/' + userId)
      .pipe(map(data => new UserDto(data)));
  }

  updateUser(user: UserDto): Observable<any> {
    return this.http$.put<any>(API_BASE_URL + '/users/' + user.id, user);
  }
  
  deleteUser(userId: number): Observable<any> {
    return this.http$.delete<any>(API_BASE_URL + '/users/' + userId);
  }

  getPartners(): Observable<UserDto[]> {
    const params: HttpParams = new HttpParams()
      .set('positionId', '3')
      .set('isActive', '1');

    return this.getUsers(params)
      .pipe(map(users => users.reverse()));
  }
}