<app-header></app-header>

<section class="section user">
  <div *ngIf="!!user" class="container">
    <h1 class="title">{{user.getFullName()}}</h1>
    <div class="user__info">
      <div class="user__prop">
        <i class="far fa-envelope"></i>
        <a href="mailto:{{user.mail}}">{{user.mail}}</a>
      </div>
      <div class="user__prop">
        <i class="fas fa-mobile-alt"></i>
        <a href="tel:{{user.getPhone()}}">{{user.getPhone()}}</a>
      </div>
      <div class="user__prop" title="Должность">
        <i class="fas fa-user-tag"></i>
        <span>{{position.name}}</span>
      </div>
    </div>
    <div class="user__action">
      <button class="btn btn-primary user__button" (click)="chatMode = !chatMode"><i class="far fa-comment"></i> Написать сообщение</button>
      <a href="tel:{{user.getPhone()}}" class="btn btn-primary user__button"><i class="fas fa-mobile-alt"></i> Позвонить</a>
    </div>

    <div *ngIf="chatMode" class="user__chat">
      <app-chat 
        *ngIf="!!chatMainMember && !!chatGuestMember"
        [mainMember]="chatMainMember" 
        [guestMember]="chatGuestMember"
      ></app-chat>
    </div>
  </div>
</section>

<app-footer></app-footer>