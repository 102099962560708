import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.styl']
})
export class ConfirmModalComponent implements OnInit {
  @Input() text: string = 'Вы действительно хотите это сделать?';
  @Input() confirmButtonText: string = 'Удалить';

  @Output() onConfirm: EventEmitter<void> = new EventEmitter();

  constructor() { }

  confirm(): void {
    this.onConfirm.emit();
    this.hide();
  }

  show(): void {
    globalThis.$('#confirmModal').modal('show');
  }

  hide(): void {
    globalThis.$('#confirmModal').modal('hide');
  }

  ngOnInit(): void {
  }

}
