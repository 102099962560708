import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, pipe } from "rxjs";
import { map } from "rxjs/operators";
import { API_BASE_URL } from "src/app/app.config";
import { ClientDto, ClientInterface } from "src/app/dto/client-dto";
import { ApiResponseDto, ApiResponseInterface } from "../dto/api-response-dto";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private http$: HttpClient) {}

  updateClientFromAmoCRM(clientId: number): Observable<ClientDto> { 
    return this.http$.post<ClientInterface>(API_BASE_URL + '/update-client-from-amocrm', {clientId})
      .pipe(map(data => new ClientDto(data)));
  }

  getClient(clientId: number): Observable<ClientDto> {
    return this.http$.get<ClientInterface>(API_BASE_URL + '/clients/' + clientId)
      .pipe(map(data => new ClientDto(data)));
  }

  addClient(client: ClientDto): Observable<number> {
    return this.http$.post<number>(API_BASE_URL + '/clients', client);
  }

  addClientFromAmoCRM(amoId: number): Observable<number> {
    return this.http$.post<number>(API_BASE_URL + '/add-client-from-amocrm', {amoId});
  }
}
