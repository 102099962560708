<app-header></app-header>

<section class="section help">
  <div class="container">
    <h1 class="title">Техническая поддержка</h1>
    <p class="help__text">Если у вас возникли вопросы, замечания или предложения, вы можете обратиться к разработчику по любому из перечисленных ниже контактов.</p>
    <div class="help__info">
      <div class="help__prop">
        <i class="far fa-envelope"></i>
        <a [href]="'mailto:' + data.mail">{{data.mail}}</a>
      </div>
      <div class="help__prop">
        <i class="fas fa-mobile-alt"></i>
        <a [href]="'tel:' + data.phone">{{data.phone}}</a>
      </div>
      <div class="help__prop">
        <i class="fab fa-vk"></i>
        <a [href]="data.vk.url" target="_blank">{{data.vk.text}}</a>
      </div>
    </div>
    <div class="help__action">
      <a [href]="getWhatsAppLink()" target="_blank"  class="btn btn-success help__button">
        <i class="fab fa-whatsapp"></i> WhatsApp
      </a>
      <a [href]="'tel:' + data.phone" class="btn btn-primary help__button">
        <i class="fas fa-mobile-alt"></i> Позвонить
      </a>
    </div>
  </div>
</section>

<app-footer></app-footer>