import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_BASE_URL } from "../app.config";
import { ClientDto, ClientInterface } from "../dto/client-dto";
import { TaskDto, TaskInterface } from "../dto/task-dto";

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  constructor(private http$: HttpClient) {}

  getTaskByAmoId(amoId: number): Observable<TaskDto> {
    const params: HttpParams = new HttpParams()
      .set("amoId", amoId.toString());

    return this.http$.get<TaskInterface>(API_BASE_URL + '/task-by-amo-id', {params})
      .pipe(map(data => new TaskDto(data))); 
  }

  // бывший TaskModalService
  addTask(task: TaskDto): Observable<number> {
    return this.http$.post<number>(API_BASE_URL + '/tasks', task);
  }

  updateTask(task: TaskDto): Observable<void> {
    return this.http$.put<void>(API_BASE_URL + '/tasks/' + task.id, task);
  }

  deleteTask(taskId: number): Observable<void> {
    return this.http$.delete<void>(API_BASE_URL + '/tasks/' + taskId);
  }

  getClientsWithoutTask(): Observable<ClientDto[]> {
    return this.http$.get<ClientInterface[]>(API_BASE_URL + '/clients-without-task')
      .pipe(map(clients => clients.map(data => new ClientDto(data))));
  }

  // бывший TasksService
  getTasksWithNewMessages(responsibleUserId: number, from: Date = null, to: Date = null, userId?: number): Observable<TaskDto[]> {
    let params: HttpParams = new HttpParams()
      .set('responsibleUserId', responsibleUserId.toString())
      .set('with', 'client');

    console.log(from !== null, to !== null);

    const getDateString = (date: Date) => {
      const dateStr: string = date.toLocaleDateString();
      const dateArr: string[] = dateStr.split('.');
      return dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0];
    };

    if (from !== null) params = params.set('date[from]', getDateString(from));
    if (to !== null) params = params.set('date[to]', getDateString(to));

    let headers = {};

    if (userId) {
      headers = {
        'Partners-User-ID': userId.toString()
      };
    }

    console.log(userId, headers);

    return this.http$.get<TaskInterface[]>(API_BASE_URL + '/tasks-with-new-messages', {headers, params})
      .pipe(map(tasks => tasks.map(data => new TaskDto(data))));
  }
  // конец

  getTasks(): Observable<TaskDto[]> {
    let params: HttpParams = new HttpParams()
      .set('with', 'client,responsibleUser,manager');

    return this.http$.get<TaskInterface[]>(API_BASE_URL + '/tasks', {params})
      .pipe(map(tasks => tasks.map(data => new TaskDto(data))));
  }

  getData(taskId: number): Observable<TaskDto> {
    const params: HttpParams = new HttpParams()
      .set('with', 'client,manager,responsibleUser');

    return this.http$.get<TaskInterface>(API_BASE_URL + '/tasks/' + taskId, {params})
      .pipe(map(data => new TaskDto(data)));
  }
}