import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_BASE_URL } from "../app.config";
import { ApiResponseDto } from "../dto/api-response-dto";
import { CredentialsDto } from "../dto/credentials-dto";
import { UserDto } from "../dto/user-dto";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http$: HttpClient) {}

  login(credentials: CredentialsDto): Observable<UserDto> {
    return this.http$.post<UserDto>(API_BASE_URL + '/login', credentials)
      .pipe(map(user => new UserDto(user)));
  }

  logout(userId: number): Observable<void> {
    return this.http$.post<void>(API_BASE_URL + '/logout', {userId});
  }

  signup(user: UserDto): Observable<number> {
    return this.http$.post<number>(API_BASE_URL + '/signup', user);
  }

  updateFCMToken(userId: number, fcmToken: string): void {
    const data = {userId, fcmToken};
    this.http$.post<void>(API_BASE_URL + '/update-fcm-token', data).subscribe();
  }
}