<div class="modal fade" tabindex="-1" id="clientModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Добавить клиента</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" class="form">
          <div class="form-group">
            <label for="name">Имя:</label>
            <input type="text" class="form-control" name="name" placeholder="Иван" formControlName="name">
            <div class="invalid-feedback" [class.show]="form.get('name').touched && form.get('name').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div class="form-group">
            <label for="surname">Фамилия: (необязательно)</label>
            <input type="text" class="form-control" name="surname" placeholder="Иванов" formControlName="surname">
            <div class="invalid-feedback" [class.show]="form.get('surname').touched && form.get('surname').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div class="form-group">
            <label for="phone">Телефон:</label>
            <input type="text" class="form-control" name="phone" placeholder="83479991122" formControlName="phone">
            <div class="invalid-feedback" [class.show]="form.get('phone').touched && form.get('phone').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div class="form-group">
            <label for="address">Адрес:</label>
            <input type="text" class="form-control" name="address" placeholder="г. Самара, ул. Мира, д. 15" formControlName="address">
            <div class="invalid-feedback" [class.show]="form.get('address').touched && form.get('address').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
          <div class="form-group">
            <label for="amoId">ID сделки AmoCRM:</label>
            <input type="number" class="form-control" name="amoId" placeholder="77777777" formControlName="amoId">
            <div class="invalid-feedback" [class.show]="form.get('amoId').touched && form.get('amoId').invalid">
              Поле обязательно к заполнению
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
        <button type="button" class="btn btn-primary" (click)="addClient()" [disabled]="form.invalid">Сохранить</button>
      </div>
    </div>
  </div>
</div>