import { Pipe, PipeTransform } from '@angular/core';
  
@Pipe({
    name: 'toDate'
})
export class ToDatePipe implements PipeTransform {
  transform(dateString: string): Date;
  transform(dateTime: number): Date;

  transform(input: any): Date {
    return new Date(input);
  }
}