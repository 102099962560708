import { TaskDto } from "../dto/task-dto";
import { TaskStatuses } from "../helpers/task-statuses.enum";

export class Interval {
  public beginTime: number;
  public endTime: number;

  public task: TaskDto = null;

  constructor(beginTime: number, duration: number = 1) {
    this.beginTime = beginTime;
    this.endTime = beginTime + duration;
  }

  getBegin(): number {
    return this.task === null ? this.beginTime : this.task.beginTime;
  }

  getEnd(): number {
    return this.task === null ? this.endTime : this.task.endTime;
  }

  getTaskAddress(): string {
    if (this.task === null) return "";
    return this.task.client.address;
  }

  getTaskShortAddress(): string {
    if (this.task === null) return "свободно";
    if (this.task.status === TaskStatuses.RESERVED) return "занято замерщиком";

    if (this.task.client.address.length <= 20) return this.task.client.address;

    return this.task.client.address.slice(0, 20) + '...';
  }

  getDuration(): string {
    return this.getEnd() - this.getBegin() + '';
  }

  getTimeWithZero(time: number): string {
    return time > 9 ? time + '' : '0' + time;
  }

  getIntervalString(): string {
    const begin: string = this.getTimeWithZero(this.getBegin());
    const end: string = this.getTimeWithZero(this.getEnd());

    return `${begin}:00 - ${end}:00`;
  }
}