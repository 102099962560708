import { WORK_END_TIME, WORK_START_TIME } from "../app.config";
import { TaskDto } from "../dto/task-dto";
import { CustomDate } from "./custom-date";
import { Interval } from "./interval";

export class Day {
  public intervals: Interval[] = [];
  public customDate: CustomDate;

  private tasks: TaskDto[];

  constructor(customDate: CustomDate, tasks: TaskDto[]) {
    this.customDate = customDate;
    this.filterTasks(tasks);
    this.initIntervals();
  }

  filterTasks(tasks: TaskDto[]): void {
    this.tasks = tasks.filter((task: TaskDto) => CustomDate.equals(new CustomDate(task.date), this.customDate));
  }

  initIntervals(): void {
    for (let time = WORK_START_TIME; time < WORK_END_TIME; time++) {
      const currentTime: number = time;

      const task: TaskDto = this.tasks.find((task: TaskDto) => task.beginTime === time);

      let interval: Interval = new Interval(time);

      if (task) {
        interval.task = task;
        time = interval.getEnd() - 1;
      }

      if (currentTime > time) {
        time = currentTime;
        continue;
      }

      this.intervals.push(interval);
    }
  }
}