import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { RESPONSE_CODE_OK } from 'src/app/app.config';
import { ApiResponseDto } from 'src/app/dto/api-response-dto';
import { ClientDto } from 'src/app/dto/client-dto';
import { TaskDto } from 'src/app/dto/task-dto';
import { UserDto } from 'src/app/dto/user-dto';
import { CustomDate } from 'src/app/model/custom-date';
import { TasksService } from 'src/app/services/tasks.service';
import { UserService } from 'src/app/services/user.service';
import { Day } from 'src/app/model/day';
import { Interval } from 'src/app/model/interval';
import { ToastComponent } from '../live-toasts/toast/toast.component';
import { forkJoin, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.styl'],
  providers: [TasksService]
})
export class TaskModalComponent implements OnInit, OnChanges {
  private onDataLoaded: Subject<void> = new Subject();
  public isDataLoaded: boolean = false;

  form: FormGroup;
  partners: UserDto[];
  clients: ClientDto[];
  tasks: TaskDto[] = [];

  beginTimes: number[];
  endTimes: number[];
  filteredEndTimes: number[];
  
  @Input("task") currentTask: TaskDto = null;
  @Input("client") currentClient: ClientDto = null;
  @Input() editMode: boolean = false;

  @Output() onClientAddBtnClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTaskAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTaskUpdate: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(ToastComponent) private _liveToast: ToastComponent;
  @ViewChild("endTime") private $endTime: ElementRef<HTMLSelectElement>;

  constructor(
    private svc$: TasksService, 
    private userSvc$: UserService,
    private app: AppComponent
    ) { }

  show(): void {
    this.initForm();
    globalThis.$('#taskModal').modal('show');
  }

  hide(): void {
    globalThis.$('#taskModal').modal('hide');
  }

  getModalTitle(): string {
    if (this.editMode) return "Редактировать задачу";
    if (this.editMode && this.app.isUserPartner()) return "Изменить время";
    if (this.app.isUserPartner()) return "Заблокировать время";
    return "Добавить задачу";
  }

  initForm(): void {
    const task: TaskDto = this.currentTask;

    this.form = new FormGroup({
      responsibleUserId: new FormControl(task?.responsibleUserId ?? '', Validators.required),
      clientId: new FormControl(task?.clientId ?? '', Validators.required),
      description: new FormControl(task?.description ?? '', Validators.required),
      date: new FormControl(task?.date ?? '', Validators.required),
      beginTime: new FormControl(task?.beginTime ?? '', Validators.required),
      endTime: new FormControl(task?.endTime ?? '', Validators.required)
    });

    globalThis.myForm = this.form;
  }

  onClientAdd(): void {
    this.onClientAddBtnClick.emit();
  }

  addTask(): void {
    const task: TaskDto = new TaskDto({
      ...this.form.value,
      managerId: this.currentTask.managerId,
      status: this.currentTask.status
    });

    this.svc$.addTask(task).subscribe((taskId: number) => {
      this.hide();
      this.app.showToast("Задачи", "Задача успешно добавлена!");
      this.onTaskAdd.emit();
      this.getTasks();
    });
  }

  updateTask(): void {
    const task: TaskDto = new TaskDto({
      ...this.currentTask,
      ...this.form.value
    });

    this.svc$.updateTask(task).subscribe(() => {
      this.hide();
      this.app.showToast("Задачи", "Задача успешно обновлена!");
      this.onTaskUpdate.emit();
    });
  }

  onFormSubmit(): void {
    if (this.editMode) {
      this.updateTask();
    } else {
      this.addTask();
    }
  }

  onPartnerChange($event: Event): void {
    const $select: HTMLSelectElement = (<HTMLSelectElement> $event.target);
    const userId: number = parseInt($select.value);
    this.getTasks(userId);

    this.form.get("date").reset();
    this.form.get("beginTime").reset();
    this.form.get("endTime").reset();
  }

  onDateChange($event: Event): void {
    const $input: HTMLInputElement = (<HTMLInputElement> $event.target);
    const customDate: CustomDate = new CustomDate($input.value);
    const day: Day = new Day(customDate, this.tasks);
    this.setFreeBeginEndTimes(day);

    this.form.get("beginTime").reset();
    this.form.get("endTime").reset();
  }

  setFreeBeginEndTimes(day: Day): void {
    const freeIntervals: Interval[] = day.intervals.filter((interval: Interval) => !interval.task);
    this.beginTimes = freeIntervals.map((interval: Interval) => interval.beginTime);
    this.endTimes = freeIntervals.map((interval: Interval) => interval.endTime);

    if (this.editMode) {
      for (let time = this.currentTask.beginTime; time < this.currentTask.endTime; time++) {
        this.beginTimes.push(time);
      }

      for (let time = this.currentTask.endTime; time > this.currentTask.beginTime; time--) {
        this.endTimes.push(time);
      }
      
      this.beginTimes = this.beginTimes.sort((t1: number, t2: number) => t1 - t2);
      this.endTimes = this.endTimes.sort((t1: number, t2: number) => t1 - t2);
    }
  }

  filterEndTimes(beginTime: number): void {
    this.filteredEndTimes = [];

    let prevTime: number = beginTime;

    for (let time of this.endTimes) {
      if (time - prevTime > 1) break;
      else if (time - beginTime < 1) continue;

      this.filteredEndTimes.push(time);
      prevTime = time;
    }
  }

  onBeginTimeChange($event: Event): void {
    const $select: HTMLSelectElement = (<HTMLSelectElement> $event.target);
    const beginTime: number = parseInt($select.value);
    this.filterEndTimes(beginTime);

    this.form.get("endTime").reset();
    setTimeout(() => {
      this.$endTime.nativeElement.value = ''; 
    });
  }

  getTasks(userId?: number, callback?: Function): void {
    if (!userId) {
      if (callback) callback();
      return;
    }

    this.svc$.getTasksWithNewMessages(userId, null, null, this.app.user.id).subscribe((tasks: TaskDto[]) => {
      this.tasks = tasks;
      this.onDataLoaded.next();
      if (callback) callback();
    });
  }

  getData(): void {
    forkJoin({
      partners: this.userSvc$.getPartners(),
      clients: this.svc$.getClientsWithoutTask()
    }).subscribe(data => {
      this.partners = data.partners;
      this.clients = data.clients;
      if (this.currentClient !== null) this.clients.push(this.currentClient);
      this.getTasks(this.currentTask?.responsibleUserId, () => {
        this.onDataLoaded.next();
      });
    });
  }

  setTaskData(): void {
    const task: TaskDto = this.currentTask;
    const customDate: CustomDate = new CustomDate(task.date);
    const day: Day = new Day(customDate, this.tasks);
    this.setFreeBeginEndTimes(day);
    this.filterEndTimes(task.beginTime);
  }

  ngOnInit(): void {
    this.getData();
    this.onDataLoaded.pipe(take(1)).subscribe(() => {
      this.isDataLoaded = true;
      if (this.currentTask) {
        this.setTaskData();
      }
      this.initForm();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.currentTask && !changes.currentClient) return;
    this.ngOnInit();
  }

}
