export interface NotificationRecipientInterface {
  id: number;
  notificationId: number;
  userId: number;
}

export class NotificationRecipientDto {
  id: number;
  notificationId: number;
  userId: number;

  constructor(options: NotificationRecipientInterface) {
    this.id = options.id;
    this.notificationId = options.notificationId;
    this.userId = options.userId;
  }
}
