import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface MapBoxResponse {
  features: {
    center: number[]
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class MapBoxService {
  private API_BASE_URL: string = 'https://api.mapbox.com';
  private ACCESS_TOKEN: string = 'pk.eyJ1Ijoic2hhZG93MjcxMSIsImEiOiJja3Uya21reTkzdnNwMzJucWQydnJsejIxIn0.bboZ6m9h7LW8tNmkrK3O9A';

  constructor(private http$: HttpClient) {}

  getCoords(query: string): Observable<number[]> {
    const params: HttpParams = new HttpParams()
      .set('access_token', this.ACCESS_TOKEN);
      // .set('bbox', this.ACCESS_TOKEN);

    return this.http$.get<MapBoxResponse>(this.API_BASE_URL + '/geocoding/v5/mapbox.places/' + encodeURIComponent('самара, ' + query) + '.json', {params})
      .pipe(map(response => response.features.length ? response.features[0].center : []));
  }
}
