<div class="chat">
  <div class="chat__header" [class.withSelected]="selectedMessages.length">
    <!-- <div class="chat__recipient recipient">
      <a 
        [routerLink]="'/users/' + guestMember.id" 
        class="recipient__name"
        title="Открыть профиль"
      >
        {{guestMember.getFullName()}}
      </a>
      <p class="recipient__position">({{getGuestMemberPosition()}})</p>
    </div> -->
    <div *ngIf="!selectedMessages.length" class="chat__recipient recipient">
      <p class="recipient__name">Чат</p>
      <p class="recipient__position">(Участников: {{members.length}})</p>
    </div>
    <div *ngIf="selectedMessages.length" class="chat__selectedControl">
      <p class="chat__selectedMessages" title="Снять выделение" (click)="deleteSelectedMessages()">
        <i class="fas fa-times"></i> Выбрано: <span>{{selectedMessages.length}}</span>
      </p>
      <div class="chat__controls" (click)="deleteSelectedMessages()">
        <div 
          *ngIf="selectedMessages.length === 1" 
          class="chat__control" 
          title="Копировать текст"
          (click)="copyMessageText(selectedMessages[0])"
        >
          <i class="far fa-clone"></i>
        </div>
        <div 
          *ngIf="selectedMessages.length === 1" 
          class="chat__control" 
          title="Редактировать сообщение"
          (click)="editMessage(selectedMessages[0])"
        >
          <i class="fas fa-pen"></i>
        </div>
        <div class="chat__control chat__control-btn" (click)="deleteMessages()">
          <button class="btn btn-danger">Удалить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="chat__body" #chatBody>
    <div *ngFor="let message of messages" class="chat__item">
      <div *ngIf="isNewDayMessage(message)" class="chat__day">{{message.getDate() | date:'d MMMM'}}</div>
      <div 
        class="message message-full" 
        [class.selected]="message.getIsSelected()" 
        [class.unread]="!message.isRead" 
        [class.editable]="isEditableMessage(message)" 
        (click)="selectMessage(message)"
      >
        <div class="message__header">
          <span class="message__sender">{{getUserName(message.memberId)}}</span>
          <span class="message__senderPosition">{{getUserPosition(message.memberId)}}</span>
          <span class="message__time">
            <span *ngIf="message.isUpdated()">изменено </span>
            {{message.getDate() | date:'HH:mm'}}
          </span>
          <div class="message__controls" (click)="$event.stopPropagation()">
            <div class="message__control" title="Копировать текст" (click)="copyMessageText(message)">
              <i class="far fa-clone"></i>
            </div>
            <div *ngIf="isEditableMessage(message)" class="message__control" title="Редактировать сообщение" (click)="editMessage(message)">
              <i class="fas fa-pen"></i>
            </div>
            <div *ngIf="isEditableMessage(message)" class="message__control text-danger" title="Удалить сообщение" (click)="deleteMessage(message)">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>
        <div class="message__content">
          {{message.text}}
        </div>
        <div *ngIf="message.attachments.length" class="message__attachments">
          <a 
            *ngFor="let image of message.attachments" 
            class="message__img"
            [attr.data-fancybox]="'messageGallery' + message.id"
            [href]="image.source">
            <img [src]="image.source" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div [formGroup]="form" class="chat__footer">
    <form *ngIf="!isDisabled() && currentMember" action="" class="form chat__form">
        <div class="form__controls">
          <div *ngIf="!isMessageEditing" class="form__addImages" title="Прикрепить изображения" (click)="fileInput.click()">
            <i class="fas fa-camera"></i>
          </div>
          <textarea
            #input 
            name="text" 
            cols="30" 
            rows="1" 
            class="form-control chat__input" 
            [placeholder]="getMessageInputPlaceholder()" 
            (input)="updateTextareaHeight()"
            (keydown.enter)="onPressEnter($event)"
            formControlName="text"
            [disableControl]="isMessageSending"
          ></textarea>
          <button *ngIf="!isMessageEditing" class="btn btn-primary chat__button" type="button" [disabled]="!canSendMessage()" (click)="sendMessage()" title="Отправить сообщение">
            <span *ngIf="isMessageSending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isMessageSending"><i class="far fa-paper-plane"></i></span>
          </button>
          <button *ngIf="isMessageEditing" class="btn btn-primary chat__button" type="button" [disabled]="!canSendMessage()" (click)="updateMessage()" title="Сохранить">
            <span *ngIf="isMessageSending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isMessageSending"><i class="fas fa-check"></i></span>
          </button>
        </div>
        <div class="form__files" [class.show]="files.length">
          <input #fileInput type="file" name="images" accept=".jpg, .jpeg, .png" multiple (change)="addFiles()">
          <p class="form__selectedFilesText">Выбранные файлы:</p>
          <div class="form__fileList">
            <app-upload-file *ngFor="let file of files" [file]="file" (uploaded)="onFileUploaded($event, file)" (removed)="onFileRemoved(file)"></app-upload-file>
          </div>
        </div>
    </form>
    <!-- <p *ngIf="task?.status !== 'active'" class="chat__status">
      <i class="fas fa-lock"></i>
      {{task?.status === "wait" ? "Ожидание подтверждения от менеджера" : ""}}
      {{task?.status === "failure" ? "Задача не выполнена" : ""}}
      {{task?.status === "success" ? "Задача выполнена" : ""}}
    </p> -->
    <p *ngIf="isDisabled()" class="chat__status">
      <i class="fas fa-lock"></i>
      {{disabled}}
    </p>
    <button *ngIf="!isDisabled() && !currentMember" class="btn btn-primary w-100" (click)="addMember()">Присоединиться</button>
  </div>
</div>

<audio #newMessageSong>
  <source src='../../assets/media/message.mp3' type="audio/mp3">
</audio>
