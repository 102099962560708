<app-header></app-header>

<section class="section users">
  <div class="container">
    <h1 class="title">Пользователи:</h1>

    <table class="table table-hover users__table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">№</th>
          <th scope="col">Имя</th>
          <th scope="col">Фамилия</th>
          <th scope="col">Роль</th>
          <th scope="col">Статус</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users; let id = index" class="user" routerLink="/users/{{user.id}}">
          <th scope="row">{{id + 1}}</th>
          <td>{{user.name}}</td>
          <td>{{user.surname}}</td>
          <td>{{getPositionName(user.positionId)}}</td>
          <td [class]="getStatusClass(user.isActive)" [class.nowrap]="true">{{getStatusName(user.isActive)}}</td>
          <td class="text-right">
            <button *ngIf="!user.isActive" class="btn btn-primary w-160" (click)="activateUser($event, user)">Активировать</button>
            <button *ngIf="user.isActive" class="btn btn-danger w-160" (click)="deactivateUser($event, user)">Деактивировать</button>
            <button class="btn btn-danger ml-10" (click)="deleteUserReason($event, user)"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<app-confirm-modal confirmButtonText="Удалить" (onConfirm)="deleteUser()"></app-confirm-modal>
<app-footer></app-footer>