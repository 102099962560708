import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AMOCRM_ORIGIN, POSITION_ADMIN, POSITION_MANAGER, POSITION_PARTNER, RESPONSE_CODE_OK } from './app.config';
import { UserDto } from './dto/user-dto';
import { CredentialsDto } from './dto/credentials-dto';
import { ToastComponent } from './components/live-toasts/toast/toast.component';
import { AuthService } from './services/auth.service';
import { AmoCRMService } from './services/amocrm.service';
import { AmoCRMWindowMessageDto } from './dto/amocrm-window-message-dto';
import firebase from 'firebase';
import { UserService } from './services/user.service';
import { EventsService } from './services/events.service';
import { NotificationsService } from './services/notifications.service';
import { NotificationDto } from './dto/notification-dto';
import { PositionsService } from './services/positions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
  title = 'partners';
  isUserLoggedIn: boolean = false;
  user: UserDto = null;

  @ViewChild(ToastComponent) liveToast: ToastComponent;

  constructor(
    private router: Router, 
    private authSvc$: AuthService,
    private amocrm$: AmoCRMService,
    private userSvc$: UserService,
    private eventsSvc$: EventsService,
    private notificationsSvc$: NotificationsService,
    private positionsSvc$: PositionsService
  ) {}

  @HostListener('window:message', ['$event'])
  handleAmoCRMLeadEvent(event: MessageEvent<string>): void {
    if (event.origin !== AMOCRM_ORIGIN) return;

    let message: AmoCRMWindowMessageDto;

    try {
      message = new AmoCRMWindowMessageDto(JSON.parse(event.data));
    } catch (err) {
      console.warn(err);
      return;
    }

    const leadId: number = parseInt(message.data);
    this.amocrm$.currentLeadId.next(leadId);
  }

  showToast(title: string, text: string, isError: boolean = false): void {
    this.notificationsSvc$.pushNotification(new NotificationDto({
      title,
      text,
      createdAt: Math.floor(Date.now() / 1000),
      isSystem: true,
      isError
    }));
  }

  isUserAdmin(): boolean {
    return this.user?.positionId === POSITION_ADMIN;
  }

  isUserManager(): boolean {
    return this.user?.positionId === POSITION_MANAGER;
  }

  isUserPartner(): boolean {
    return this.user?.positionId === POSITION_PARTNER;
  }

  isUserActive(): boolean {
    return this.user.isActive;
  }

  navigateToHome(): void {
    if (this.isUserPartner()) {
      this.router.navigate(['/tasks']);
    }

    if (this.isUserAdmin() || this.isUserManager()) {
      this.router.navigate(['/calendar']);
    }
  }

  handleRouterEvents(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url !== "/auth/logout") this.checkUserActivate();
        if (location.pathname === "/") this.navigateToHome();
      }
    });
  }

  getAllowUrl(pathname: string): string {
    if (pathname === "/auth" || pathname === "/loading") {
      return "/";
    }

    return pathname;
  }

  checkAuth(currentRouterUrl: string): void {
    const credentials: CredentialsDto = JSON.parse(localStorage.getItem("partnersCreds"));

    if (credentials !== null) {
      this.authSvc$.login(credentials).subscribe((user: UserDto) => {
        console.log(user);
        this.user = user;
        this.isUserLoggedIn = true;
        this.router.navigate([currentRouterUrl]);
        this.getFirebaseMessagingToken();
        this.eventsSvc$.connectToSSE();
      }, error => {
        this.isUserLoggedIn = false;
        this.router.navigate(['/auth']);
      });
    } else {
      this.router.navigate(['/auth']);
    }
  }

  checkUserActivate(): void {
    if (this.user !== null && !this.isUserActive()) {
      this.router.navigate(['/deactivated']);
    }
  }

  getFirebaseMessagingToken(): void {
    try {
      const vapidKey: string = 'BEGkA26UKbzqh6reGfZVv8t9DQ6tZuDHb6w3z-Kjr3f114d4btsbZwzJfcNU2NWirZNeIa8k3DNN8UM3MlCnLjM';
      const messaging = firebase.messaging();

      messaging.getToken({vapidKey}).then((currentToken) => {
        if (currentToken) {
          this.authSvc$.updateFCMToken(this.user.id, currentToken);
        } else {
          this.authSvc$.updateFCMToken(this.user.id, null);
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    } catch(err) {
      console.error('Handled Error:', err);
    }
  }

  ngOnInit(): void {
    const currentRouterUrl = this.getAllowUrl(location.pathname);
    this.router.navigate(['/loading']);

    this.positionsSvc$.getPositions().subscribe(() => {
      this.checkAuth(currentRouterUrl);
      this.handleRouterEvents();
    }); 
  }
}
