import { ModelTypes } from "../helpers/model-types.enum";
import { NotificationRecipientDto, NotificationRecipientInterface } from "./notification-recipient-dto";

export interface NotificationInterface {
  id?: number;
  title: string;
  text: string;
  link?: string;
  createdAt: number;
  isRead?: boolean;
  modelId?: number;
  modelType?: ModelTypes;

  embedded?: {
    recipients?: NotificationRecipientInterface[]
  }

  // Только на фронте
  isSystem?: boolean;
  isError?: boolean;
}

export class NotificationDto {
  id?: number;
  title: string;
  text: string;
  link?: string = '/';
  createdAt: number;
  isRead?: boolean;
  modelId?: number;
  modelType?: ModelTypes;

  recipients?: NotificationRecipientDto[] = [];

  // Только на фронте
  isSystem?: boolean = false;
  isError?: boolean = false;

  constructor(options: NotificationInterface) {
    this.id = options.id ?? 0;
    this.title = options.title;
    this.text = options.text;
    this.link = options.link ?? '/';
    this.createdAt = options.createdAt;
    this.isRead = options.isRead ?? false;
    this.modelId = options.modelId;
    this.modelType = options.modelType;
    this.isSystem = options.isSystem ?? false;
    this.isError = options.isError ?? false;

    if (options.embedded) {
      if (options.embedded.recipients) {
        this.recipients = options.embedded.recipients.map(data => new NotificationRecipientDto(data));
      }
    }
  }
}
