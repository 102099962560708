import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CredentialsDto } from 'src/app/dto/credentials-dto';
import { PositionDto } from 'src/app/dto/position-dto';
import { UserDto } from 'src/app/dto/user-dto';
import { PositionRoles } from 'src/app/helpers/position-roles.enum';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { PositionsService } from 'src/app/services/positions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'auth-main',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.styl']
})
export class AuthComponent implements OnInit {
  isSignupMode: boolean = false;
  loginForm: FormGroup;
  signupForm: FormGroup;

  // Уведомление
  showAlert: boolean = false;
  alertMessage: string = "Успех!";
  alertClass: string = "success";

  positions: PositionDto[] = [];

  constructor(
    private svc$: AuthService, 
    private router: Router,
    private app: AppComponent,
    private positionsSvc$: PositionsService,
    private userSvc$: UserService,
    private eventsSvc$: EventsService
    ) { }

  initForms(): void {
    this.loginForm = new FormGroup({
      mail: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

    this.signupForm = new FormGroup({
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required]),
      mail: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
      // passwordConfirm: new FormControl('', Validators.required),
    });
  }

  changeMode() {
    this.isSignupMode = !this.isSignupMode;
  }
  
  loginUser() {
    const controls = this.loginForm.controls;

    const mail = controls['mail'].value;
    const password = controls['password'].value;

    const credentials: CredentialsDto = new CredentialsDto({mail, password});

    this.svc$.login(credentials).subscribe((user: UserDto) => {
      this.app.isUserLoggedIn = true;
      this.app.user = user;
      localStorage.setItem('partnersCreds', JSON.stringify(credentials));
      this.router.navigate(['/']);
      this.app.getFirebaseMessagingToken();
      this.eventsSvc$.connectToSSE();
    }, (errorResponse: HttpErrorResponse) => {
      this.showAlert = true;
      this.alertMessage = errorResponse.error;
      this.alertClass = "danger";
    });
  }

  signupUser() {
    const controls = this.signupForm.controls;

    const user: UserDto = new UserDto();
    user.name = controls['name'].value;
    user.surname = controls['surname'].value;
    user.phone = controls['phone'].value;
    user.mail = controls['mail'].value;
    user.password = controls['password'].value;
    user.positionId = controls['position'].value;

    this.svc$.signup(user).subscribe(() => {
      this.showAlert = true;
      this.alertMessage = "Регистрация прошла успешно!";
      this.alertClass = "success";
      this.isSignupMode = false;
    }, (errorResponse: HttpErrorResponse) => {
      this.showAlert = true;
      this.alertMessage = errorResponse.error;
      this.alertClass = "danger";
    });
  }

  logoutUser(): void {
    this.svc$.logout(this.app.user.id).subscribe(() => {
      this.app.isUserLoggedIn = false;
      this.app.user = null;
      localStorage.removeItem("partnersCreds");
      this.router.navigate(['/auth']);
    });
  }

  checkLogout(): void {
    if (location.pathname === "/auth/logout") {
      this.logoutUser();
    } 
  }

  filterPositions(): void {
    this.positionsSvc$.getPositions().subscribe(positions => {
      this.positions = positions.filter(position => position.role !== PositionRoles.ADMIN);
    });
  }

  ngOnInit(): void {
    this.filterPositions();
    this.initForms();
    this.checkLogout();
  }
}
