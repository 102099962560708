import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { RESPONSE_CODE_OK } from 'src/app/app.config';
import { ApiResponseDto } from 'src/app/dto/api-response-dto';
import { PositionDto } from 'src/app/dto/position-dto';
import { UserDto } from 'src/app/dto/user-dto';
import { PositionsService } from 'src/app/services/positions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.styl'],
})
export class UserComponent implements OnInit {
  user: UserDto;
  position: PositionDto;

  chatMode: boolean = false;
  chatMainMember: UserDto;
  chatGuestMember: UserDto;

  constructor(
    private userSvc$: UserService, 
    private router: Router,
    private app: AppComponent,
    private positionsSvc$: PositionsService
    ) { }

  getUser(): void {
    const userId: number = +location.pathname.slice("/users/".length);

    if (isNaN(userId)) {
      this.router.navigate(['/']);
      return;
    }

    this.userSvc$.getUser(userId).subscribe((user: UserDto) => {
      this.user = user;
      this.getPosition();
      this.getChatMembers();
    }, error => {
      console.log(error);
      this.router.navigate(['/']);
    });
  }

  getPosition(): void {
    this.position = this.positionsSvc$.getPosition(this.user.positionId);
  }

  getChatMembers(): void {
    this.chatMainMember = this.app.user;
    this.chatGuestMember = this.user;
  }

  ngOnInit(): void {
    this.getUser();
  }

}
