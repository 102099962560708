import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { RESPONSE_CODE_OK } from 'src/app/app.config';
import { TaskModalComponent } from 'src/app/components/task-modal/task-modal.component';
import { ApiResponseDto } from 'src/app/dto/api-response-dto';
import { ClientDto } from 'src/app/dto/client-dto';
import { EventDto } from 'src/app/dto/event-dto';
import { TaskDto } from 'src/app/dto/task-dto';
import { UserDto } from 'src/app/dto/user-dto';
import { TaskStatuses } from 'src/app/helpers/task-statuses.enum';
import { ClientService } from 'src/app/services/client.service';
import { EventsService } from 'src/app/services/events.service';
import { GeoTreeService } from 'src/app/services/geotree.service';
import { MapBoxService } from 'src/app/services/mapbox.service';
import { TasksService } from 'src/app/services/tasks.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.styl'],
  providers: [TasksService]
})
export class TaskComponent implements OnInit, OnDestroy {
  taskId: number;
  data: TaskDto = new TaskDto();
  clientData: ClientDto = new ClientDto();
  yandexMapsURL: URL = new URL('https://yandex.ru/maps/');
  googleMapsURL: URL = new URL('https://www.google.com/maps/dir/?api=1&travelmode=driving');

  chatMode: boolean = false;

  manager: UserDto;
  partner: UserDto;

  chatMainMember: UserDto;
  chatGuestMember: UserDto;

  public isDataLoaded: boolean = false;

  @ViewChild(TaskModalComponent) private taskModal: TaskModalComponent;

  private alive: boolean = false;

  constructor(
    private svc$: TasksService, 
    private router$: Router, 
    public app: AppComponent,
    private userSvc$: UserService,
    private clientSvc$: ClientService,
    private eventsSvc$: EventsService,
    private mapBoxSvc$: MapBoxService,
    private geoTreeSvc$: GeoTreeService,
    private router: Router
  ) {}

  getChatDisabledStatus(): string {
    switch(this.data.status) {
      case "wait":
        return "Ожидание подтверждения от менеджера";
      case "failure":
        return "Задача не выполнена";
      case "success":
        return "Задача выполнена";
      default:
        return null;
    }
  }

  updateClientFromAmoCRM(): void {
    this.clientSvc$.updateClientFromAmoCRM(this.clientData.id).subscribe((client: ClientDto) => {
      this.clientData = client;
      this.app.showToast("Клиенты", "Данные о клиенте успешно импортированы из AmoCRM!");
    }, (errorResponse: HttpErrorResponse) => {
      this.app.showToast("Клиенты", errorResponse.error ?? 'Сделка с таким ID не найдена в AmoCRM!', true);
    });
  }

  updateStatus(status: TaskStatuses) {
    const task: TaskDto = new TaskDto({...this.data, status});

    this.svc$.updateTask(task).subscribe(() => {
      this.app.showToast("Задачи", "Статус задачи успешно обновлён!");
      this.onTaskUpdate();
    });
  }

  editTask(): void {
    this.taskModal.show();
  }

  deleteTask(): void {
    this.svc$.deleteTask(this.data.id).subscribe(() => {
      this.app.showToast("Задачи", "Задача успешно удалена!");
      this.router$.navigate(['/']);
    });
  }

  onTaskUpdate(): void {
    this.getData();
  }

  isUserPartner(): boolean {
    return this.app.isUserPartner();
  }

  getTaskId(): void {
    const url: URL = new URL(location.href);
    const path: string = url.pathname;

    this.taskId = +path.slice(7);

    if ( isNaN(this.taskId) ) {
      this.router$.navigate(['/']);
    }
  }

  getChatMembers(): void {
    if (this.app.isUserPartner()) {
      this.chatMainMember = this.partner;
      this.chatGuestMember = this.manager;
    } else {
      this.chatMainMember = this.manager;
      this.chatGuestMember = this.partner;
    }
  }

  getYandexMapsURL(): void {
    this.geoTreeSvc$.getCoords(this.clientData.address).subscribe(coords => {
      this.yandexMapsURL.searchParams.set('pt', coords.join(','));
      this.isDataLoaded = true;
    });
  }
  
  getGoogleMapsURL(): void {
    this.googleMapsURL.searchParams.set('destination', 'Samara, ' + this.clientData.address);
  }

  getData(): void {
    this.svc$.getData(this.taskId).subscribe((task: TaskDto) => {
      this.data = task;
      this.clientData = task.client;
      this.manager = task.manager;
      this.partner = task.responsibleUser;
      // this.getYandexMapsURL();
      this.getGoogleMapsURL();
      this.isDataLoaded = true;
      // this.getChatMembers();
    },(error: any) => {
      this.app.navigateToHome();
    });
  }

  initTaskEventsHandler(): void {
    this.eventsSvc$.getEvents('tasks_update')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        if (event.content.modelId === this.data?.id) {
          this.getData();
        }
      });

    this.eventsSvc$.getEvents('tasks_delete')
      .pipe(takeWhile(e => this.alive))
      .subscribe((event: EventDto) => {
        if (event.content.modelId === this.data?.id) {
          this.app.navigateToHome();
        }
      });
  }

  initRouterEventsHandler(): void {
    this.router.events.pipe(takeWhile(data => this.alive)).subscribe(event => {
      this.reboot();
    });
  }

  reboot(): void {
    this.isDataLoaded = false;
    this.getTaskId();
    this.getData();
  }

  ngOnInit(): void {
    this.alive = true;
    this.getTaskId();
    this.getData();
    this.initTaskEventsHandler();
    this.initRouterEventsHandler();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
