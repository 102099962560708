import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface GeoTreeObject {
  type: string;
  geo_center?: {
    lon: number,
    lat: number
  };
}

@Injectable({
  providedIn: 'root'
})
export class GeoTreeService {
  private SAMARA_LON: number = 50.100202;
  private SAMARA_LAT: number = 53.195878;
  private API_BASE_URL: string = 'https://api.geotree.ru';

  constructor(private http$: HttpClient) {}

  getCoords(query: string): Observable<number[]> {
    const params: HttpParams = new HttpParams()
      .set('term', 'самара, ' + query)
      .set('limit', '1');

    return this.http$.get<GeoTreeObject[]>(this.API_BASE_URL + '/address.php', {params})
      .pipe(map(objects => {
        if (objects.length === 0) return [this.SAMARA_LON, this.SAMARA_LAT];
        return [
          objects[0].geo_center?.lon ?? this.SAMARA_LON, 
          objects[0].geo_center?.lat ?? this.SAMARA_LAT
        ];
      }));
  }
}
