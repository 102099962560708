import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.styl']
})
export class ToastComponent implements OnInit, AfterViewInit {
  @Input() title: string = "Partners";
  @Input() text: string = "У Вас новое уведомление.";
  @Input() link: string = "/";
  @Input() isError: boolean = false;
  @Input('date') dateStr: string;
  date: Date;

  constructor() { }

  getCharCodesSum(str: string): number {
    return str.split('').map(c => c.charCodeAt(0)).reduce((s, n) => s + n, 0);
  }

  getId(): string {
    return 'toast_' + (this.getCharCodesSum(this.title) + this.getCharCodesSum(this.text));
  }

  show(): void {
    globalThis.$('#' + this.getId()).toast('show');
  }

  hide(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    globalThis.$('#' + this.getId()).toast('hide');
  }

  createDateFromStr(): void {
    if (this.dateStr !== undefined) {
      this.date = new Date(this.dateStr);
      return;
    }

    this.date = new Date();
  }

  ngOnInit(): void {
    this.createDateFromStr();
  }

  ngAfterViewInit(): void {
    this.show();
  }

}
