import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.styl']
})
export class HelpComponent implements OnInit {
  data = {
    mail: "g.vakil@yandex.ru",
    phone: "+79053560848",
    vk: {
      url: "https://vk.me/vakil.gayfullin",
      text: "Вакиль Гайфуллин"
    },
    whatsappText: "Здравствуйте! У меня возник вопрос по приложению Partners"
  };

  constructor() { }

  getWhatsAppLink(text?: string): string {
    if (!text) text = this.data.whatsappText;

    const url: URL = new URL("https://wa.me/" + parseInt(this.data.phone));
    url.searchParams.set("text", text);
    return url.href;
  }

  ngOnInit(): void {
  }

}
